import retry from 'async-retry';
import Axios from 'axios';
import axios, { AxiosError } from 'axios';
import { signIn } from 'next-auth/react';
import { i18n } from 'next-i18next';

import { ConsumerGenerator } from '../../components/_organisms/DesignerTabPane/GeneratorsTab/utils';
import { CuripodElement } from '../../components/SlideComponents/slateUtil';
import { Slide } from '../../components/SlideComponents/slide.types';
import { AiAssessedScore } from '../../frontendServices/gameAPI';
import { CustomerDoc } from '../../hooks/useCustomer';
import {
  toGeneratorSearchParams,
  toSearchParams,
} from '../../hooks/useDiscoverSearchQueryState';
import { newRelicNoticeError } from '../../hooks/useNewRelic';
import { ReportInsight, ReportInsightType } from '../../hooks/useReportInsights';
import { IndexedTenant } from '../../hooks/useTenant';
import { TenorGIF } from '../../hooks/useTenorGIFs';
import pkg from '../../package.json';
import { preventRedirectOn401Pahtnames } from '../../utils/backend-config';
import { getDestinationTenantFromUrl } from '../../utils/navigator';
import { LicenseCoarseLevels } from '../../utils/tenantPlanUtils';
import { NEW_TEMPLATE_PARAM } from '../../utils/urlUtils';
import { PublicGeneratorDTO } from '../CoreAPIService/CoreAPIService.types';
import { Creator, CreatorFollower } from '../CreatorService/Creator.type';
import { ById } from '../database/types';
import Metrics from '../metrics/metrics';
import {
  AiConsumerContent,
  Folder,
  FolderEditOrCreateDTO,
  TemplateEditOrCreateDTO,
  Workspace,
} from '../TemplateService/Template.type';
import { Template } from '../TemplateService/Template.type';
import { TenantUser } from '../TenantUserService/TenantUserService';
import {
  ActivityResults,
  Collection,
  CropConfig,
  DiscoveredTemplate,
  DiscoveredTemplateStatus,
  DiscoverSearchQuery,
  DiscoverSearchResults,
  DistrictUsageData,
  EmailInvitationCreateDTO,
  Game,
  GameEditOrCreateDTO,
  IndexedTemplate,
  Invitation,
  InvitationRole,
  LeaderBoard,
  MSClaims,
  PaginatedGameResults,
  Presentation,
  PresentationDTO,
  PromptInputData,
  PromptResourceMap,
  PromptSingleTestCaseResult,
  PromptTestcase,
  Quote,
  QuoteAndUsage,
  Response,
  RoundOptions,
  RoundType,
  SchoolUsageData,
  Score,
  Standards,
  SupportedIntegrationLabel,
  SupportedIntegrationResponse,
  TopicInvitation,
  UserTenant,
} from './types';

const getAuthHeader = (token: string, destinationTenantId?: string) => {
  const base: ById<string> = {
    Authorization: `Bearer ${token}`,
    'x-app-version': `v${pkg.version}`,
  };
  if (destinationTenantId) {
    base['x-api-tenant'] = destinationTenantId;
  }
  return base;
};
const getUnAuthHeader = (destinationTenantId?: string) => {
  const base: ById<string> = {
    'x-app-version': `v${pkg.version}`,
  };
  if (destinationTenantId) {
    base['x-api-tenant'] = destinationTenantId;
  }
  return base;
};

export default class BackendService {
  static instance: BackendService;
  url = '';
  idToken: string | null = null;
  cdnUrl = '';
  deferredRefreshRequests: { resolve: () => void; reject: () => void }[] = [];
  isRefreshingTokens = false;
  constructor() {
    if (BackendService.instance) {
      return BackendService.instance;
    }
    BackendService.instance = this;
    return this;
  }

  public init(url: string, cdnURL: string) {
    BackendService.instance.url = url;
    // BackendService.instance.auth = auth;
    BackendService.instance.cdnUrl = cdnURL;

    if (process.env.isLocal) {
      BackendService.instance.url = 'http://localhost:3010';
    }
  }

  public setToken = (token: string) => {
    this.idToken = token;
  };

  private clearToken = () => {
    this.idToken = null;
  };

  private getToken() {
    const token = this.idToken;
    if (!token) {
      throw new Error('Token does not exists');
    }
    return token;
  }
  private getRefreshToken = async () => {
    if (!this.isRefreshingTokens) {
      // We will fetch a new token

      // set to true to make sure this is the only one actually fetching
      this.isRefreshingTokens = true;
      try {
        const res = await axios.get<{ id_token: string }>(
          `${window.location.origin}/api/auth/refreshToken`,
        );
        const token = res.data.id_token;

        // Set the fresh token in memory
        this.setToken(token);

        // resolve all deferred requests
        this.deferredRefreshRequests.forEach(cb => cb.resolve());

        // reset
        this.deferredRefreshRequests = [];
        this.isRefreshingTokens = false;
      } catch (e) {
        // Token could not be refreshed

        // remove the token in memory
        this.clearToken();

        // reject all deferred requests
        this.deferredRefreshRequests.forEach(cb => cb.reject());
        newRelicNoticeError(e, 'Error while refreshing token');

        if (this.redirectOnFail(window.location.pathname)) {
          // redirect the user back to where you are currently
          await signIn('azure-ad-b2c', { callbackUrl: window.location.href });
        }
      }
    } else {
      // There is a pending request - wait for it
      return new Promise<void>((resolve, reject) => {
        // Push this promise to the deferredRequests array to that they can be
        // resolved or rejected by the singular refresh token fetcher
        this.deferredRefreshRequests.push({
          resolve,
          reject,
        });
      });
    }
  };
  private redirectOnFail(pathname: string) {
    if (pathname === '/') return false;
    if (pathname.startsWith('/discover')) return false;
    if (pathname.startsWith('/blog')) return false;
    if (pathname.endsWith('/live/results')) return false;
    if (preventRedirectOn401Pahtnames.includes(pathname)) return false;

    // checking if the pathname is /:tenantId/lessons/:lessonId
    const pathnameSplitBySlash = window.location.pathname.split('/');
    if (pathnameSplitBySlash.length === 4 && pathnameSplitBySlash[2] === 'lessons') {
      return false;
    }
    return true;
  }

  public getSignOutUrl = async (): Promise<string> => {
    return retry(async () => {
      const res = await axios.post<{ url: string }>(
        `${window.location.origin}/api/auth/signoutUrl`,
      );
      return res.data.url;
    });
  };

  private async retryWithFreshTokenIf401<T>(cb: (token: string) => Promise<T>) {
    try {
      const token = this.getToken();
      return await cb(token);
    } catch (error) {
      if ((error as AxiosError).response?.status === 401) {
        // In case of 401, the token might be invalidated by the server, so we need a fresh one and retry
        // This might attempt a redirect
        await this.getRefreshToken();
        return await cb(this.getToken());
      }
      if ((error as AxiosError).response?.status === 500) {
        newRelicNoticeError(
          error,
          'Error while fetching data from backend, retryWithFreshTokenIf401',
        );
      }
      throw error as AxiosError;
    }
  }

  public getWebImage = async (url?: string) => {
    if (!url) return Promise.resolve(undefined);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const res = await axios.get<any>(url);
    return res;
  };
  public setLanguage = async (lang: string) => {
    const res = await axios.post<void>(
      `${window.location.origin}/api/lang/setLangCookie`,
      {
        lang,
      },
    );
    return res;
  };

  public async joinTenant(invitationCode: string) {
    return this.retryWithFreshTokenIf401(async token => {
      const res = await axios.post<{
        roles: MSClaims;
        tenantId: string;
      }>(
        `${this.url}/v0.5/auth/selfServeJoin`,
        { invitationCode },
        {
          headers: getAuthHeader(token),
        },
      );
      return res.data;
    });
  }

  public async getSchoolsInZip(zipCode: string) {
    return retry(async () => {
      const res = await axios.get(
        `${this.url}/v0.5/schoolDatabase/public/getByZipCode/${zipCode}`,
      );
      return res.data;
    });
  }

  public async copyTemplate(templateId: string, tenantId: string) {
    const tenants = await this.retrieveCurrentTenants();
    const destinationTenantId = tenants.user.activeTenantId;
    return this.retryWithFreshTokenIf401(async token => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res = await axios.post<{ template: Template; errors: any[] }>(
        `${this.url}/v0.5/templates/copyTemplate`,
        { templateId, tenantId },
        {
          headers: getAuthHeader(token, destinationTenantId),
        },
      );
      return { ...res.data, tenantId: destinationTenantId };
    });
  }

  public async copyAsTemplate(gameId: string, tenantId: string) {
    const tenants = await this.retrieveCurrentTenants();
    const destinationTenantId = tenants.user.activeTenantId;
    return this.retryWithFreshTokenIf401(async token => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res = await axios.post<{ template: Template; errors: any[] }>(
        `${this.url}/v0.5/games/copyGameAsTemplate`,
        { gameId, tenantId },
        {
          headers: getAuthHeader(token, destinationTenantId),
        },
      );
      return { ...res.data, tenantId: destinationTenantId };
    });
  }

  //   public async editTenantName(name: string) {
  //     return this.retryWithFreshTokenIf401(async token => {
  //       const res = await axios.put<{ tenantId: string; tenantName: string }>(
  //         `${this.url}/v0.5/tenants/${gentlyCheckDestinationTenantUrl()}/editName`,
  //         {
  //           name,
  //         },
  //         {
  //           headers: getAuthHeader(token, gentlyCheckDestinationTenantUrl()),
  //         },
  //       );
  //       return res.data;
  //     });
  //   }
  public async retrieveCurrentTenants() {
    return this.retryWithFreshTokenIf401(async token => {
      const res = await axios.get<{
        user: UserTenant;
      }>(`${this.url}/v0.5/tenants/myTenants`, {
        // Pass on the x-api-header to the api if we think we know the destination tenant
        headers: getAuthHeader(token),
      });
      return res.data;
    });
  }

  public async switchTenant(tenantId: string, activeTenantId?: string) {
    return this.retryWithFreshTokenIf401(async token => {
      const res = await axios.post<UserTenant>(
        `${this.url}/v0.5/tenants/switch`,
        { tenantId },
        {
          headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)),
        },
      );
      return res.data;
    });
  }
  public async getUploadUrl(pathToFile: string) {
    const tenants = await this.retrieveCurrentTenants();
    const activeTenantId = tenants.user.activeTenantId;
    return this.retryWithFreshTokenIf401(async token => {
      return await axios.post<string>(
        `${this.url}/v0.5/files/uploadUrl`,
        { pathToFile: pathToFile },
        {
          headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)),
        },
      );
    });
  }

  public async getUploadUrlV2({
    pathToFile,
    imageType,
    mimeType,
    tenantId,
  }: {
    pathToFile: string;
    imageType: string;
    mimeType: string;
    tenantId: string;
  }) {
    return this.retryWithFreshTokenIf401(async token => {
      return await axios.post<{ url: string; uploadId: string }>(
        `${this.url}/v0.5/files/uploadUrlV2`,
        { pathToFile, fileType: imageType, mimeType },
        {
          headers: getAuthHeader(token, getDestinationTenantFromUrl(tenantId)),
        },
      );
    });
  }

  public async markUploadSuccessfulV2(uploadId: string, tenantId: string) {
    return this.retryWithFreshTokenIf401(async token => {
      return await axios.post(
        `${this.url}/v0.5/files/markUploadSuccessV2`,
        { uploadId: uploadId },
        {
          headers: getAuthHeader(token, getDestinationTenantFromUrl(tenantId)),
        },
      );
    });
  }

  public async getAdminUploadUrl(pathToFile: string) {
    return this.retryWithFreshTokenIf401(async token => {
      return await axios.post<string>(
        `${this.url}/v0.5/files/admin/uploadUrl`,
        { pathToFile: pathToFile },
        {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        },
      );
    });
  }

  public async getAdminUploadUrlV2({
    pathToFile,
    imageType,
    mimeType,
  }: {
    pathToFile: string;
    imageType: string;
    mimeType: string;
  }) {
    return this.retryWithFreshTokenIf401(async token => {
      return await axios.post<{ url: string; uploadId: string }>(
        `${this.url}/v0.5/files/admin/uploadUrlV2`,
        { pathToFile, fileType: imageType, mimeType },
        {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        },
      );
    });
  }

  public async getDownloadUrl(filename: string, folder?: string) {
    return this.retryWithFreshTokenIf401(async token => {
      return await axios.post<{ url: string }>(
        `${this.url}/v0.5/files/downloadUrl`,
        { filename: filename, folder },
        {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        },
      );
    });
  }
  public async getPublicDownloadUrl(filename: string, folder?: string) {
    return await axios.post<{ url: string }>(
      `${this.url}/v0.5/files/public/downloadUrl`,
      { filename: filename, folder },
      {
        headers: getUnAuthHeader(getDestinationTenantFromUrl()),
      },
    );
  }

  generatorPlugin = {
    generateLearningStandard: async ({
      prompt,
    }: {
      prompt: {
        searchValue: string | undefined;
        standardSelected: string | undefined;
        subjectSelected: string | undefined;
        gradeSelected: string | undefined;
      };
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const queryParams = new URLSearchParams();
        queryParams.set('prompt', prompt.searchValue || '');
        queryParams.set('state', prompt.standardSelected || '');
        queryParams.set('subject', prompt.subjectSelected || '');
        queryParams.set('grade', prompt.gradeSelected || '');
        const query = queryParams.toString();
        const res = await axios.get<PromptResourceMap>(
          `${this.url}/v0.5/standards/generate${query ? `?${query}` : ''}`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );

        return res.data;
      });
    },
    generateExampleAnswers: async ({
      prompt,
      userInputFormData,
      resourceMap,
      categories,
    }: {
      prompt: string;
      userInputFormData: { id: string; type: string; value: string; fileType?: string }[];
      resourceMap: PromptResourceMap;
      categories: { id: string; prompt: string }[];
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<PromptResourceMap>(
          `${this.url}/v0.5/generators/generate-student-answers`,
          {
            prompt,
            resourceMap,
            userInputFormData,
            categories,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    generateSinglePromptTestCase: async ({
      presentationId,
      promptId,
      userInputFormData,
      resourceMap,
    }: {
      presentationId: string;
      promptId: string;
      userInputFormData: { id: string; type: string; value: string; fileType?: string }[];
      resourceMap: PromptResourceMap;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<PromptSingleTestCaseResult>(
          `${this.url}/v0.5/generators/generate-by-prompt`,
          {
            presentationId,
            promptId,
            resourceMap,
            userInputFormData,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    generateSingleSlidePromptTestCase: async ({
      presentationId,
      slideId,
      userInputFormData,
      resourceMap,
    }: {
      presentationId: string;
      slideId: string;
      userInputFormData: { id: string; type: string; value: string; fileType?: string }[];
      resourceMap: PromptResourceMap;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<PromptSingleTestCaseResult>(
          `${this.url}/v0.5/generators/generate-by-slide-prompt`,
          {
            presentationId,
            slideId,
            resourceMap,
            userInputFormData,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    generateSingleSlideMulitResponsePromptTestCase: async ({
      presentationId,
      slideId,
      userInputFormData,
      resourceMap,
    }: {
      presentationId: string;
      slideId: string;
      userInputFormData: { id: string; type: string; value: string; fileType?: string }[];
      resourceMap: PromptResourceMap;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<PromptResourceMap>(
          `${this.url}/v0.5/generators/generate-by-slide-multi-response-prompt`,
          {
            presentationId,
            slideId,
            resourceMap,
            userInputFormData,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );

        return res.data;
      });
    },

    getGeneratorThumbnailUrl: (fileId: string) => `${this.cdnUrl}/${fileId}`,
    getGeneratorCoverImageUploadUrl: async (imageId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        return await axios.post<string>(
          `${this.url}/v0.5/files/global/uploadUrl`,
          { pathToFile: `generators/${imageId}` },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
      });
    },

    getGeneratorCoverImageUploadUrlV2: async ({
      imageId,
      imageType,
      mimeType,
    }: {
      imageId: string;
      imageType: string;
      mimeType: string;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        return await axios.post<{ url: string; uploadId: string }>(
          `${this.url}/v0.5/files/global/uploadUrlV2`,
          { pathToFile: `generators/${imageId}`, fileType: imageType, mimeType },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
      });
    },

    fetchSvg: async ({ fileId }: { fileId: string }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const url = `${this.url}/v0.5/generators/${fileId}`;
        const res = await axios.request<Blob>({
          method: 'get',
          url,
          responseType: 'blob',
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        const objectUrl = URL.createObjectURL(res.data);
        return objectUrl;
      });
    },
  };
  versionControl = {
    listPresentationVersions: async ({
      presentationId,
      cursor,
      limit,
    }: {
      presentationId: string;
      cursor?: string;
      limit?: number;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const queryParams = new URLSearchParams();
        if (cursor) queryParams.set('cursor', cursor);
        if (limit) queryParams.set('limit', limit.toString());
        const query = queryParams.toString();
        const res = await axios.get<Presentation[]>(
          `${this.url}/v0.5/presentations/${presentationId}/versions${
            query ? `?${query}` : ''
          }`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    restorePresentationVersion: async ({
      presentationId,
      versionId,
    }: {
      presentationId: string;
      versionId: string;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<Presentation>(
          `${this.url}/v0.5/presentations/${presentationId}/versions/${versionId}/restore`,
          {},
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
  };
  ai = {
    consumeGeneratorAsNewPresentation: async (
      {
        generatorId,
        userInputFormData,
      }: {
        generatorId: string;
        userInputFormData: PromptInputData[];
      },
      activeTenantId: string,
    ) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<Template>(
          `${this.url}/v0.5/generators/generate-presentation`,
          { presentationId: generatorId, userInputFormData },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)),
            timeout: 1000 * 120,
          },
        );
        return res.data;
      });
    },
    createDynamicLesson: async (
      {
        prompt,
        grade,
        subject,
        fileType,
        filePath,
        languages,
        lessonCategory,
      }: {
        prompt: string;
        grade: string;
        subject: string | null;
        fileType: string | null;
        filePath: string | null;
        languages: string[];
        lessonCategory: string | null;
      },
      activeTenantId: string,
    ) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios
          .post<Template>(
            `${this.url}/v0.5/generators/generate-dynamic-lesson`,
            {
              prompt,
              grade,
              subject,
              fileType,
              filePath,
              languages,
              lessonCategory,
            },
            {
              headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)),
              timeout: 1000 * 240,
              timeoutErrorMessage: 'Request timed out after 4 minutes',
            },
          )
          .catch(error => {
            if (error.code === 'ECONNABORTED') {
              Metrics.getLogger().logEvent('AI.DynamicLesson.Timeout', {
                prompt,
                grade,
                subject,
                fileType,
                filePath,
                languages,
                tenantId: activeTenantId,
                lessonCategory,
              });
            }
            throw error;
          });
        return res.data;
      });
    },
    consumeGenericGeneratorAsNewPresentation: async (
      {
        prompt,
        grade,
        subject,
        fileType,
        filePath,
      }: {
        prompt: string;
        grade: string;
        subject: string | null;
        fileType: string | null;
        filePath: string | null;
      },
      activeTenantId: string,
    ) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<Template>(
          `${this.url}/v0.5/generators/generate-generic-lesson`,
          { prompt, grade, subject, fileType, filePath },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)),
            timeout: 1000 * 120,
          },
        );
        return res.data;
      });
    },
    consumeGenerator: async ({
      generatorId,
      userInputFormData,
    }: {
      generatorId: string;
      userInputFormData: PromptInputData[];
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<Slide[]>(
          `${this.url}/v0.5/generators/generate-slides`,
          { presentationId: generatorId, userInputFormData, nVersions: 1 },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
            timeout: 1000 * 120,
          },
        );
        return res.data;
      });
    },

    getTestcases: async ({
      presentationId,
    }: {
      presentationId: string;
    }): Promise<PromptTestcase[]> => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<PromptTestcase[]>(
          `${this.url}/v0.5/presentations/${presentationId}/testcases`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    newTestcase: async ({
      inputData,
      presentationId,
    }: {
      inputData: PromptInputData[];
      presentationId: string;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<PromptTestcase>(
          `${this.url}/v0.5/presentations/${presentationId}/testcases`,
          { inputData },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    runTestcases: async ({
      testcaseIds,
      presentationId,
      useSlidesAsSampleLesson = false,
    }: {
      testcaseIds: string[];
      presentationId: string;
      useSlidesAsSampleLesson?: boolean;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<
          ById<{
            slides: Slide[];
            allTestcasePromptOutputs?: PromptSingleTestCaseResult;
          }>
        >(
          `${this.url}/v0.5/generators/generate-by-testcase`,
          { testcaseIds, presentationId, useSlidesAsSampleLesson },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    duplicateTestcase: async ({
      presentationId,
      testcaseid,
    }: {
      testcaseid: string;
      presentationId: string;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<PromptTestcase>(
          `${this.url}/v0.5/presentations/${presentationId}/testcases/${testcaseid}/duplicate`,
          {},
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    editTestcase: async ({
      inputData,
      presentationId,
      testcaseId,
      isSuggestion,
      isSampleLesson,
    }: {
      inputData: PromptInputData[];
      presentationId: string;
      testcaseId: string;
      isSuggestion?: boolean;
      isSampleLesson?: boolean;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.put<PromptTestcase>(
          `${this.url}/v0.5/presentations/${presentationId}/testcases/${testcaseId}`,
          { inputData, isSuggestion, isSampleLesson },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    deleteTestcase: async ({
      testcaseIds,
      presentationId,
    }: {
      testcaseIds: string[];
      presentationId: string;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.delete<PromptTestcase>(
          `${this.url}/v0.5/presentations/${presentationId}/testcases`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
            data: { testcaseIds },
          },
        );
        return res.data;
      });
    },
    retrieveGenerator: async (generatorId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<DiscoveredTemplate>(
          `${this.url}/v0.5/generators/${generatorId}`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    createOrEditGenerator: async ({
      generatorId,
      status,
      isPremiumGenerator,
      generatorPlan,
      coverImageAltText,
      coverImageId,
      backgroundImageId,
      learningStandards,
      durationInMins,
    }: {
      generatorId: string;
      status: DiscoveredTemplateStatus;
      isPremiumGenerator?: boolean;
      generatorPlan?: LicenseCoarseLevels | null;
      coverImageAltText?: string;
      coverImageId?: string;
      backgroundImageId?: string;
      learningStandards?: string[];
      durationInMins?: number;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.put<DiscoveredTemplate>(
          `${this.url}/v0.5/generators/${generatorId}`,
          {
            status,
            coverImageAltText,
            coverImageId,
            backgroundImageId,
            isPremiumGenerator,
            generatorPlan,
            learningStandards,
            durationInMins: isNaN(Number(durationInMins)) ? 0 : durationInMins,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    listGenerators: async ({
      includeMine,
      includeStaging,
    }: {
      includeStaging: boolean;
      includeMine: boolean;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const searchParams = new URLSearchParams();
        if (includeStaging) {
          searchParams.set('includeStaging', 'true');
        }
        if (includeMine) {
          searchParams.set('includeMine', 'true');
        }

        const query = searchParams.toString();

        const res = await axios.get<{
          items: (DiscoveredTemplate & { aiContent: AiConsumerContent })[];
          cursor: string;
        }>(
          `${this.url}/v0.5/generators${query.length > 0 ? '?' : ''}${
            query ? query : ''
          }`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },

    getStatus: async () => {
      const res = await axios.get<boolean>(`${this.url}/v0/heartbeat/ai-status`, {
        headers: getUnAuthHeader(getDestinationTenantFromUrl()),
      });
      return res.data;
    },
    getInsights: async ({
      livePresentationId,
      reportType,
    }: {
      livePresentationId: string;
      reportType: ReportInsightType;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<ReportInsight>(
          `${this.url}/v0.5/reports/${livePresentationId}/insight/${reportType}`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    editReportInsight: async ({
      insightId,
      promptOutput,
    }: {
      insightId: string;
      promptOutput: string;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<ReportInsight | undefined>(
          `${this.url}/v0.5/reports/${insightId}`,
          { promptOutput },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    deleteReportInsight: async ({
      reportType,
      livePresentationId,
    }: {
      reportType: ReportInsightType;
      livePresentationId: string;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.delete<ReportInsight | undefined>(
          `${this.url}/v0.5/reports/${livePresentationId}/reportType/${reportType}`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    getParticipantInsights: async ({
      livePresentationId,
      reportType,
      participantId,
    }: {
      livePresentationId: string;
      reportType: ReportInsightType;
      participantId?: string;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<ReportInsight>(
          `${this.url}/v0.5/reports/${livePresentationId}/insight/${reportType}/participant/${participantId}`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    generateReportInsights: async ({
      livePresentationId,
      reportType,
      participantId,
    }: {
      livePresentationId: string;
      reportType: ReportInsightType | 'all';
      participantId?: string;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<ReportInsight | undefined>(
          `${this.url}/v0.5/reports/${livePresentationId}/generateInsights`,
          {
            reportType,
            participantId,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },

    generateReportInsightsForAllStudents: async ({
      livePresentationId,
    }: {
      livePresentationId: string;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<ReportInsight[]>(
          `${this.url}/v0.5/reports/${livePresentationId}/generateInsights/allParticipants`,
          {},
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    generateTestResultInsights: async ({
      gameId,
      systemInstruction,
      userInstruction,
      insightId,
      temperature,
    }: {
      gameId: string;
      systemInstruction?: string;
      userInstruction?: string;
      insightId: string;
      temperature?: number;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<
          { insight: string; formattedTemplate: string } | undefined
        >(
          `${this.url}/v0.5/reports/${gameId}/generateInsights/promptTesting`,
          {
            userInstruction: userInstruction || null,
            systemInstruction: systemInstruction || null,
            insightId,
            temperature: temperature || null,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
  };
  invitations = {
    listPending: async (topicId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<Invitation[]>(
          `${this.url}/v0.5/invitations/pending/${topicId}`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    revoke: async (code: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<void>(
          `${this.url}/v0.5/invitations/revoke`,
          { code },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    bulkInvite: async (invitations: EmailInvitationCreateDTO[]) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<Invitation[]>(
          `${this.url}/v0.5/invitations/invite`,
          { invitations },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    generate: async (
      role: InvitationRole,
      topic?: TopicInvitation,
      duration?: number,
    ) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<Invitation>(
          `${this.url}/v0.5/invitations/generate`,
          {
            role,
            topic,
            // Null should be a never expiring invitationLink
            expireHours: duration === -1 ? null : duration,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
  };
  profile = {
    getProfileImageUrl: async (activeTenantId?: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        return await axios.get<string>(
          `${this.url}/v0.5/files/profile/getProfileImageUrl`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)),
          },
        );
      });
    },
    getUploadUrl: async (imageId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        return await axios.post<string>(
          `${this.url}/v0.5/files/profile/uploadUrl`,
          { imageId: imageId },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
      });
    },

    getUploadUrlV2: async ({
      imageId,
      imageType,
      mimeType,
    }: {
      imageId: string;
      imageType: string;
      mimeType: string;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        return await axios.post<{ url: string; uploadId: string }>(
          `${this.url}/v0.5/files/profile/uploadUrlV2`,
          { imageId, fileType: imageType, mimeType },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
      });
    },

    updateProfileImageId: async (profileImageId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        return await axios.post<string>(
          `${this.url}/v0.5/tenants/updateProfileImageId`,
          { profileImageId: profileImageId },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
      });
    },
  };

  generators = {
    latest: async () => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<ConsumerGenerator[]>(
          `${this.url}/v0.5/generators/latest`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    listByIds: async (ids: string[]) => {
      if (ids.length === 0) return [] as ConsumerGenerator[];
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<ConsumerGenerator[]>(
          `${this.url}/v0.5/generators/byIds?ids=${ids.join(',')}`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    publicListByIds: async ({
      collectionId,
      cursor,
      limit,
    }: {
      collectionId: string;
      cursor: number;
      limit: number;
    }) => {
      const res = await axios.request<{
        items: ConsumerGenerator[];
        cursor: number;
        total: number;
      }>({
        method: 'get',
        url: `${this.url}/v0.5/generators/public/byCollectionId/${collectionId}?cursor=${cursor}&limit=${limit}`,
      });
      return res.data;
    },
    listRecommended: async () => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<{ generators: ConsumerGenerator[] }>(
          //ids are used as fallback if no customizable lessons for the subjects are found, as we allow custom text subjects
          `${this.url}/v0.5/generators/recommended`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    retrieve: async (generatorId: string) => {
      const res = await axios.get<ConsumerGenerator>(
        `${this.url}/v0.5/generators/search/${generatorId}`,
        {
          headers: getUnAuthHeader(getDestinationTenantFromUrl()),
        },
      );
      return res.data;
    },
    search: async ({
      cursor,
      grades,
      categories,
      limit,
      subjects,
      creatorId,
      searchPhrase,
      statuses,
    }: {
      cursor: number;
      subjects: string[];
      categories: string[];
      grades: string[];
      limit: number;
      creatorId?: string;
      searchPhrase?: string;
      statuses: string[];
    }) => {
      const queryString = toGeneratorSearchParams({
        cursor,
        subjects,
        categories: categories.filter(c => c !== 'none'), // hack to prevent searching with the "none" category
        grades,
        searchPhrase,
        creatorId,
        statuses,
        limit,
      });
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<{
          items: ConsumerGenerator[];
          cursor: number;
          total: number;
        }>(`${this.url}/v0.5/generators/search/?${queryString.toString()}`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },
    searchByStandard: async ({ standardId }: { standardId: string }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<{
          items: ConsumerGenerator[];
        }>(`${this.url}/v0.5/generators/searchByStandard/${standardId}`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },
    listSimilarGenerators: async ({ generatorId }: { generatorId: string }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<ConsumerGenerator[]>(
          `${this.url}/v0.5/generators/listSimilar?id=${generatorId}`,
          {},
        );
        return res.data;
      });
    },
    ssr: {
      retrieve: async (generatorId: string, activeTenantId: string) => {
        return this.retryWithFreshTokenIf401(async token => {
          const res = await axios.get<PublicGeneratorDTO>(
            `${this.url}/v0.5/generators/public/${generatorId}`,
            {
              headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)),
            },
          );
          return res.data;
        });
      },
    },
  };

  discover = {
    admin: {
      syncTemplate: async ({
        templateId,
        tenantId,
      }: {
        templateId: string;
        tenantId: string;
      }) => {
        return this.retryWithFreshTokenIf401(async token => {
          const res = await axios.post<DiscoveredTemplate>(
            `${this.url}/v0.5/discover/admin/syncTemplate`,
            {
              templateId,
              tenantId,
            },
            {
              headers: getAuthHeader(token, getDestinationTenantFromUrl()),
            },
          );
          return res.data;
        });
      },
      editAdminDiscoverTemplate: async ({
        adminTags,
        adminLanguage,
        templateId,
        title,
        description,
        coverImageId,
        coverImageAltText,
      }: {
        templateId: string;
        adminTags: string[];
        adminLanguage?: string;
        title?: string;
        description?: string | null;
        coverImageId?: string | null;
        coverImageAltText?: string | null;
      }) => {
        return this.retryWithFreshTokenIf401(async token => {
          const res = await axios.post<DiscoveredTemplate>(
            `${this.url}/v0.5/discover/admin/edit`,
            {
              templateId,
              adminTags,
              adminLanguage,
              title,
              description,
              coverImageId,
              coverImageAltText,
            },
            {
              headers: getAuthHeader(token, getDestinationTenantFromUrl()),
            },
          );
          return res.data;
        });
      },
      retrieve: async (templateId: string, templateTenantId: string) => {
        return this.retryWithFreshTokenIf401(async token => {
          const res = await axios.get<DiscoveredTemplate & { shouldSync: boolean }>(
            `${this.url}/v0.5/discover/admin/t/${templateId}/tt/${templateTenantId}`,
            {
              headers: getAuthHeader(token, getDestinationTenantFromUrl()),
            },
          );
          return res.data;
        });
      },
      list: async () => {
        return this.retryWithFreshTokenIf401(async token => {
          const res = await axios.get<DiscoveredTemplate[]>(
            `${this.url}/v0.5/discover/admin/list`,
            {
              headers: getAuthHeader(token, getDestinationTenantFromUrl()),
            },
          );
          return res.data;
        });
      },
      listPaginated: async (limit: number, cursor?: string, id?: string) => {
        return this.retryWithFreshTokenIf401(async token => {
          let url = `${this.url}/v0.5/discover/admin/listPaginated?limit=${limit}`;
          if (cursor) url = url + `&cursor=${cursor}`;
          if (id) url = url + `&id=${id}`;
          const res = await axios.get<{ items: DiscoveredTemplate[]; cursor?: string }>(
            url,
            {
              headers: getAuthHeader(token, getDestinationTenantFromUrl()),
            },
          );
          return res.data;
        });
      },
      approve: async (body: { templateId: string; tenantId: string }) => {
        return this.retryWithFreshTokenIf401(async token => {
          const res = await axios.post(`${this.url}/v0.5/discover/admin/approve`, body, {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          });
          return res.data;
        });
      },
      reject: async (body: { templateId: string }) => {
        return this.retryWithFreshTokenIf401(async token => {
          const res = await axios.put(`${this.url}/v0.5/discover/admin/reject`, body, {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          });
          return res.data;
        });
      },
    },
    anonymised: {
      randomIcebreaker: async (cursor?: number) => {
        const res = await axios.post<DiscoveredTemplate[]>(
          `${this.url}/v0.5/discover/public/icebreaker`,
          { cursor },
        );
        return res.data;
      },
      query: async (query: DiscoverSearchQuery) => {
        const queryString = query ? `?${toSearchParams(query).toString()}` : '';

        const res = await axios.get<DiscoverSearchResults>(
          `${this.url}/v0.5/discover/public/${queryString}`,
        );
        return res.data;
      },
    },
    query: async (query: DiscoverSearchQuery) => {
      return this.retryWithFreshTokenIf401(async token => {
        const queryString = query ? `?${toSearchParams(query).toString()}` : '';

        const res = await axios.get<DiscoverSearchResults>(
          `${this.url}/v0.5/discover/${queryString}`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    getTemplateThumbnailUrl: (fileId: string) => `${this.cdnUrl}/${fileId}.jpeg`,
    getGeneratorThumbnailUrl: (fileId: string) => `${this.cdnUrl}/${fileId}`,

    getTemplateThumbnail: async (_: string, fileId: string) => {
      const res = await axios.request<Blob>({
        method: 'get',
        url: `${this.cdnUrl}/${fileId}.jpeg`,
        responseType: 'blob',
      });
      const objectUrl = URL.createObjectURL(res.data);
      return objectUrl;
    },
    getGeneratorThumbnail: async (_: string, fileId: string) => {
      const res = await axios.request<Blob>({
        method: 'get',
        url: `${this.cdnUrl}/${fileId}`,
        responseType: 'blob',
      });
      const objectUrl = URL.createObjectURL(res.data);
      return objectUrl;
    },
    getCreatorThumbnailUrl: (fileId: string) => `${this.cdnUrl}/${fileId}.jpeg`,
    getCreatorThumbnail: async (fileId: string) => {
      const res = await axios.request<Blob>({
        method: 'get',
        url: `${this.cdnUrl}/${fileId}.jpeg`,
        responseType: 'blob',
      });
      const objectUrl = URL.createObjectURL(res.data);
      return objectUrl;
    },
    getCreator: async (creatorId: string) => {
      const res = await axios.request<Creator>({
        method: 'get',
        url: `${this.url}/v0.5/creator/public/${creatorId}`,
      });
      return res.data;
    },
    getCreatorWithAuth: async (creatorId: string, activeTenantId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.request<CreatorFollower | undefined>({
          method: 'get',
          url: `${this.url}/v0.5/creator/${creatorId}/isfollowing`,
          headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)),
        });
        return res.data;
      });
    },

    followCreator: async (creatorId: string, activeTenantId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.request<CreatorFollower>({
          method: 'post',
          url: `${this.url}/v0.5/creator/${creatorId}/follow`,
          headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)),
        });
        return res.data;
      });
    },
    unfollowCreator: async (creatorId: string, activeTenantId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<Creator & { follower?: CreatorFollower }>(
          `${this.url}/v0.5/creator/${creatorId}/unfollow`,
          {},
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)),
          },
        );
        return res.data;
      });
    },

    getCreatorTemplates: async (creatorId: string, limit: number, cursor: number) => {
      const res = await axios.request<DiscoverSearchResults>({
        method: 'get',
        url: `${this.url}/v0.5/creator/public/${creatorId}/lessons?cursor=${cursor}&limit=${limit}`,
      });
      return res.data;
    },
    publishTemplate: async (templateId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post(
          `${this.url}/v0.5/discover/discovertemplate`,
          { templateId },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    getTemplates: async () => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get(`${this.url}/v0.5/discover/`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },
    getIndexedTemplate: async (templateId: string) => {
      const res = await axios.get<IndexedTemplate>(
        `${this.url}/v0.5/discover/public/${templateId}`,
        {
          headers: getUnAuthHeader(getDestinationTenantFromUrl()),
        },
      );
      return res.data;
    },
    getFrontPageTemplates: async () => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<DiscoverExploreTemplates>(
          `${this.url}/v0.5/discover/explore?lang=${i18n?.language}`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    onDiscoverRoundsAdded: async (slides: Slide[]) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<void>(
          `${this.url}/v0.5/templates/onDiscoverSlidesAdded`,
          {
            slides,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    addToMyLessons: async (templateId: string, activeTenantId?: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<Template>(
          `${this.url}/v0.5/discover/addToMyLessons`,
          { templateId },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)),
          },
        );
        return res.data;
      });
    },
    updateCreatorProfileImage: async (profileImageId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        await axios.post(
          `${this.url}/v0.5/creator/updateThumbnail`,
          { profileImageId: profileImageId },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
      });
    },
  };
  workspace = {
    newWorkspace: (data: FolderEditOrCreateDTO) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<Workspace | undefined>(
          `${this.url}/v0.5/workspace`,
          data,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    getWorkspace: async (workspaceId: string, activeTenantId?: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<Folder>(`${this.url}/v0.5/workspace/${workspaceId}`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)),
        });
        return res.data;
      });
    },
    listWorkspaces: async (activeTenantId?: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<Workspace[]>(`${this.url}/v0.5/workspace`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)),
        });
        return res.data;
      });
    },
    saveWorkspace: (changes: { title: string }, workspaceId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.put<Workspace>(
          `${this.url}/v0.5/workspace/${workspaceId}`,
          { changes: changes },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    restoreWorkspaceTemplate: async (workspaceId: string, templateId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<Template>(
          `${this.url}/v0.5/workspace/${workspaceId}/templates/${templateId}/restore`,
          {},
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    restoreWorkspaceFolder: async (workspaceId: string, folderId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<Folder>(
          `${this.url}/v0.5/workspace/${workspaceId}/folders/${folderId}/restore`,
          {},
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },

    listWorkspaceTemplates: (
      workspaceId: string,
      parentId?: string,
      tenantId?: string,
    ): Promise<Template[]> => {
      let queryString = '';
      if (parentId) {
        queryString += '?parentId=' + parentId;
      }
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<Template[]>(
          `${this.url}/v0.5/workspace/${workspaceId}/templates${queryString}`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl(tenantId)),
          },
        );
        return res.data;
      });
    },
    listWorkspaceFolders: (workspaceId: string, tenantId?: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<Folder[]>(
          `${this.url}/v0.5/workspace/${workspaceId}/folders`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl(tenantId)),
          },
        );
        return res.data;
      });
    },
    listDeletedWorkspaceTemplates: (workspaceId: string): Promise<Template[]> => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<Template[]>(
          `${this.url}/v0.5/workspace/${workspaceId}/templates/deleted`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    listDeletedWorkspaceFolders: (workspaceId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<Folder[]>(
          `${this.url}/v0.5/workspace/${workspaceId}/folders/deleted`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
  };
  users = {
    me: async (activeTenantId?: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<TenantUser>(`${this.url}/v0.5/users/me`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)),
        });
        return res.data;
      });
    },
    editMyProfile: async (editedProfile: Partial<TenantUser>) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<{ nickName: string }>(
          `${this.url}/v0.5/users/me/editProfile`,
          {
            username: editedProfile.username,
            bio: editedProfile.bio,
            description: editedProfile.description,
            accountType: editedProfile.accountType,
            templateDefaultSharingPublic: editedProfile.templateDefaultSharingPublic,
            useNewDesigner: editedProfile.useNewDesigner,
            schoolRole: editedProfile.schoolRole,
            subjects: editedProfile.subjects,
            grades: editedProfile.grades,
            schoolCountry: editedProfile.schoolCountry,
            schoolId: editedProfile.schoolId,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    setNickName: async (newNickName: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<{ nickName: string }>(
          `${this.url}/v0.5/users/me/setNickName`,
          { newNickName: newNickName },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    setEmailPresubmitrences: async (enabled: boolean, email: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<{ nickName: string }>(
          `${this.url}/v0.5/users/me/setEmailPreferences`,
          { enabled: enabled, email: email },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    deleteMe: async () => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<{ success: boolean }>(
          `${this.url}/v0/users/me/delete`,
          {},
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },

    getUserPhotoUrl: async (uid: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        return await axios.get<string>(
          `${this.url}/v0.5/files/profile/getProfileImageUrl/${uid}`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
      });
    },

    startTrial: async () => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<{ success: boolean }>(
          `${this.url}/v0.5/users/me/startTrial`,
          {},
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
  };

  public sendHeartbeat = async (activeTenantId: string) => {
    return this.retryWithFreshTokenIf401(async token => {
      const res = await axios.get<{ success: boolean }>(`${this.url}/v0/heartbeat`, {
        headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)),
      });
      return res.data;
    });
  };

  tenant = {
    getCountries: async () => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get(`${this.url}/v0.5/payments/countries`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },
    updateEmail: async (email: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.put(
          `${this.url}/v0.5/payments/accounts`,
          { email },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    getPrices: async (activeTenantId?: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const res = await axios.get<{ prices: any[] }>(
          `${this.url}/v0.5/payments/prices`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)),
          },
        );
        return res.data;
      });
    },
    get: async (activeTenantId?: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<IndexedTenant>(
          `${this.url}/v0.5/tenants/${activeTenantId}`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)),
          },
        );
        return res.data;
      });
    },
    getAccount: async (activeTenantId?: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<CustomerDoc | ''>(
          `${this.url}/v0.5/payments/accounts`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)),
          },
        );
        if (res.data === '') return null;
        return res.data;
      });
    },
    listInvoices: async () => {
      return this.retryWithFreshTokenIf401(async token => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const res = await axios.get<{ data: any[] }>(
          `${this.url}/v0.5/payments/accounts/invoices`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    getUpcomingInvoice: async () => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<{ amount_remaining: number; currency: string }>(
          `${this.url}/v0.5/payments/accounts/invoices/upcoming`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    getEmailDomains: async (activeTenantId?: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<string[]>(`${this.url}/v0.5/tenants/emailDomains`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)),
        });
        return res.data;
      });
    },
    editEmailDomains: async (emailDomains: string[], activeTenantId?: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.put<string[]>(
          `${this.url}/v0.5/tenants/emailDomains`,
          { emailDomains: emailDomains },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)),
          },
        );
        return res.data;
      });
    },
    editUserProfile: async (changes: Partial<TenantUser>) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.put(
          `${this.url}/v0.5/tenants/user/${changes.uid}`,
          {
            changes: changes,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    retrievePaymentMethod: async () => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<{
          brand: string;
          expMonth: number;
          expYear: number;
          last4: string;
        }>(`${this.url}/v0.5/payments/accounts/paymentMethod`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },
    removeUser: async (uid: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.delete(`${this.url}/v0.5/tenants/user/${uid}`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },
    subscribe: async ({
      quantity,
      priceId,
      promoCode,
      country,
      activeTenantId,
    }: {
      quantity: number;
      priceId: string;
      promoCode?: string;
      country?: string;
      activeTenantId?: string;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<{
          subscriptionId: string;
          clientSecret: string | null;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          latest_invoice: any;
          freePremiumClaimed?: boolean | null;
        }>(
          `${this.url}/v0.5/payments/accounts/subscriptions`,
          { priceId, quantity, promoCode, country },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)),
          },
        );
        return res.data;
      });
    },
    listUsers: async (activeTenantId?: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<{ users: TenantUser[]; byId: ById<TenantUser> }>(
          `${this.url}/v0.5/tenants/users`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)),
          },
        );
        return res.data;
      });
    },
    previewChangeSubscription: async ({
      priceId,
      quantity,
      activeTenantId,
    }: {
      priceId: string;
      quantity: number;
      activeTenantId?: string;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const res = await axios.post<any>(
          `${this.url}/v0.5/payments/accounts/subscriptions/previewchanges`,
          { priceId, quantity },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)),
          },
        );
        return res.data;
      });
    },
    changeSubscription: async ({
      priceId,
      promoCode,
      quantity,
      activeTenantId,
    }: {
      priceId: string;
      quantity: number;
      promoCode?: string;
      activeTenantId?: string;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.put(
          `${this.url}/v0.5/payments/accounts/subscriptions`,
          { priceId, quantity, promoCode },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)),
          },
        );
        return res.data;
      });
    },
    cancelSubscription: async (activeTenantId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post(
          `${this.url}/v0.5/payments/accounts/subscriptions/cancel`,
          {},
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)),
          },
        );
        return res.data;
      });
    },
    newCard: async () => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<{ clientSecret: string }>(
          `${this.url}/v0.5/payments/accounts/cards`,
          {},
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    getUser: async (uid: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<TenantUser>(`${this.url}/v0.5/tenants/user/${uid}`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },
    copyLessonToTenant: async ({
      toTenantId,
      fromTenantId,
      lessonId,
    }: {
      toTenantId: string;
      fromTenantId: string;
      lessonId: string;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<TenantUser>(
          `${this.url}/v0.5/tenants/copyLessonBetweenTenants`,
          {
            toTenantId,
            fromTenantId,
            lessonId,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    copyFolderToTenant: async ({
      toTenantId,
      fromTenantId,
      folderId,
    }: {
      toTenantId: string;
      fromTenantId: string;
      folderId: string;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<TenantUser>(
          `${this.url}/v0.5/tenants/copyFolderBetweenTenant`,
          {
            toTenantId,
            fromTenantId,
            folderId,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
  };

  gif = {
    search: async ({
      search,
      limit = 20,
      cursor,
    }: {
      search?: string;
      limit?: number;
      cursor?: string;
    }) => {
      const queryParams = new URLSearchParams();
      queryParams.set('limit', limit?.toString());
      if (cursor) {
        queryParams.set('cursor', cursor);
      }

      if (search) {
        queryParams.set('query', search);
      }

      const query = queryParams.toString();

      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<{
          cursor?: string;
          data: TenorGIF[];
        }>(`${this.url}/v0.5/gif/search?${query}`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },
  };

  blueprint = {
    saveFolder: (changes: FolderEditOrCreateDTO) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.put<Folder>(
          `${this.url}/v0.5/folders/${changes.id}`,
          { changes: changes },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    duplicateTemplate: async (templateId: string, parentId: string | null) => {
      const tenantId = getDestinationTenantFromUrl();
      return this.retryWithFreshTokenIf401(async token => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const res = await axios.post<{ template: Template; errors: any[] }>(
          `${this.url}/v0.5/templates/copyTemplate`,
          { templateId, tenantId, parentId },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    retrieveTemplate: (templateId: string): Promise<Template | undefined> => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<Template | undefined>(
          `${this.url}/v0.5/templates/${templateId}`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    retrieveTemplateAuthenticated: (
      templateId: string,
      tenantId: string,
    ): Promise<Template | undefined> => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<Template | undefined>(
          `${this.url}/v0.5/templates/private/${templateId}/tenantId/${tenantId}`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    retrieveTemplateAuthenticatedPostgres: (
      templateId: string,
      tenantId: string,
    ): Promise<Template | undefined> => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<Template | undefined>(
          `${this.url}/v0.5/templates/private/${templateId}/tenantId/${tenantId}/postgres`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    retriveTemplateUnauthenticated: async (
      tenantId: string,
      templateId: string,
    ): Promise<Template | undefined> => {
      const res = await axios.get<Template | undefined>(
        `${this.url}/v0.5/templates/public/${templateId}/tenantId/${tenantId}`,
        {
          headers: getUnAuthHeader(getDestinationTenantFromUrl()),
        },
      );
      return res.data;
    },
  };
  gameFiles = {
    importPresentation: async (
      templateId: string,
      fileId: string,
      fileExtension: string,
    ) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<Slide[]>(
          `${this.url}/v0.5/templates/${templateId}/importPresentation`,
          {
            presentationFilePath: fileId,
            presentationFileExtension: fileExtension,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },

    fetchCroppedImage: async ({
      fileId,
      isAuthenticated,
      isFromDiscover,
      cropConfig,
      isOwner,
    }: {
      fileId: string;
      cropConfig?: CropConfig | null;
      isAuthenticated?: boolean;
      isFromDiscover: boolean;
      isOwner: boolean;
    }) => {
      const query = cropConfig
        ? `?h=${cropConfig.height}&w=${cropConfig.width}&x=${cropConfig.x}&y=${cropConfig.y}`
        : undefined;
      if (isFromDiscover) {
        const url = `${this.url}/v0.5/discover/public/files/${fileId}${
          query ? query : ''
        }`;
        const res = await axios.request<Blob>({
          method: 'get',
          url,
          responseType: 'blob',
        });
        const objectUrl = URL.createObjectURL(res.data);
        return objectUrl;
      }
      // This image is not from discover - we use game_images endpoints
      if (isAuthenticated === true && isOwner) {
        return this.retryWithFreshTokenIf401(async token => {
          const url = `${this.url}/v0.5/game_images/files/${fileId}${query ? query : ''}`;
          const res = await axios.request<Blob>({
            method: 'get',
            url,
            responseType: 'blob',
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          });
          const objectUrl = URL.createObjectURL(res.data);
          return objectUrl;
        });
      }
      if (isAuthenticated === true && !isOwner) {
        const url = `${this.url}/v0.5/game_images/public/files/${fileId}${
          query ? query : ''
        }`;
        const res = await axios.request<Blob>({
          method: 'get',
          url,
          responseType: 'blob',
          headers: getUnAuthHeader(getDestinationTenantFromUrl()),
        });
        const objectUrl = URL.createObjectURL(res.data);
        return objectUrl;
      }

      if (isAuthenticated === false) {
        const url = `${this.url}/v0.5/game_images/public/files/${fileId}${
          query ? query : ''
        }`;
        const res = await axios.request<Blob>({
          method: 'get',
          url,
          responseType: 'blob',
          headers: getUnAuthHeader(getDestinationTenantFromUrl()),
        });
        const objectUrl = URL.createObjectURL(res.data);
        return objectUrl;
      }
    },

    fetchAttachmentContent: async ({
      fileId,
    }: {
      fileId: string;
      isAuthenticated?: boolean;
      isFromDiscover: boolean;
    }): Promise<CuripodElement[]> => {
      const url = `${this.url}/v0.5/game_images/public/filesV2/${fileId}`;
      const res = await axios.request<string>({
        method: 'get',
        url,
        headers: {
          ...getUnAuthHeader(getDestinationTenantFromUrl()),
          'Cache-Control': 'no-cache',
        },
      });

      const signedUrl = res.data;

      const rawFileContent = await axios.request<{
        version: number;
        type: 'passage';
        content: CuripodElement[];
      }>({
        method: 'get',
        url: signedUrl,
        responseType: 'json',
      });

      return rawFileContent.data.content;
    },

    fetchCroppedImageV2: async ({
      fileId,
      isAuthenticated,
      isFromDiscover,
      cropConfig,
      isOwner,
    }: {
      fileId: string;
      cropConfig?: CropConfig | null;
      isAuthenticated?: boolean;
      isFromDiscover: boolean;
      isOwner: boolean;
    }) =>
      await retry(
        async (retrier, attemptNumber) => {
          const query = cropConfig
            ? `?h=${cropConfig.height}&w=${cropConfig.width}&x=${cropConfig.x}&y=${cropConfig.y}`
            : undefined;
          if (isFromDiscover) {
            const url = `${this.url}/v0.5/discover/public/filesV2/${fileId}${
              query ? query : ''
            }`;

            const res = await axios.request<string>({
              method: 'get',
              url,
              headers: {
                ...getUnAuthHeader(getDestinationTenantFromUrl()),
                ...(attemptNumber > 1 && { 'Cache-Control': 'no-cache' }),
              },
            });

            const signedUrl = res.data;

            //we want to prefetch the image so also get the image blob from cdn
            const cdnImageResponse = await axios.request<Blob>({
              method: 'get',
              url: signedUrl,
              responseType: 'blob',
            });
            const objectUrl = URL.createObjectURL(cdnImageResponse.data);
            return objectUrl;
          }
          if (isAuthenticated === true && isOwner) {
            return this.retryWithFreshTokenIf401(async token => {
              const url = `${this.url}/v0.5/game_images/filesV2/${fileId}${
                query ? query : ''
              }`;
              const res = await axios.request<string>({
                method: 'get',
                url,
                headers: {
                  ...getAuthHeader(token, getDestinationTenantFromUrl()),
                  ...(attemptNumber > 1 && { 'Cache-Control': 'no-cache' }),
                },
              });

              const signedUrl = res.data;

              //we want to prefetch the image so also get the image blob from cdn
              const cdnImageResponse = await axios.request<Blob>({
                method: 'get',
                url: signedUrl,
                responseType: 'blob',
              });
              const objectUrl = URL.createObjectURL(cdnImageResponse.data);
              return objectUrl;
            });
          } else {
            const url = `${this.url}/v0.5/game_images/public/filesV2/${fileId}${
              query ? query : ''
            }`;
            const res = await axios.request<string>({
              method: 'get',
              url,
              headers: {
                ...getUnAuthHeader(getDestinationTenantFromUrl()),
                ...(attemptNumber > 1 && { 'Cache-Control': 'no-cache' }),
              },
            });

            const signedUrl = res.data;

            //we want to prefetch the image so also get the image blob from cdn
            const cdnImageResponse = await axios.request<Blob>({
              method: 'get',
              url: signedUrl,
              responseType: 'blob',
            });
            const objectUrl = URL.createObjectURL(cdnImageResponse.data);
            return objectUrl;
          }
        },
        {
          retries: 3,
        },
      ),

    fetchSvgImage: async ({
      fileId,
      isAuthenticated,
      isFromDiscover,
      cropConfig,
      isOwner,
    }: {
      fileId: string;
      cropConfig?: CropConfig | null;
      isAuthenticated?: boolean;
      isFromDiscover: boolean;
      isOwner: boolean;
    }) =>
      await retry(
        async (retrier, attemptNumber) => {
          const query = cropConfig
            ? `?h=${cropConfig.height}&w=${cropConfig.width}&x=${cropConfig.x}&y=${cropConfig.y}`
            : undefined;
          if (isFromDiscover) {
            const url = `${this.url}/v0.5/discover/public/filesV2/${fileId}${
              query ? query : ''
            }`;

            const res = await axios.request<string>({
              method: 'get',
              url,
              headers: {
                ...getUnAuthHeader(getDestinationTenantFromUrl()),
                ...(attemptNumber > 1 && { 'Cache-Control': 'no-cache' }),
              },
            });

            const signedUrl = res.data;

            //we want to prefetch the image so also get from cdn
            const cdnImageResponse = await axios.request({
              method: 'get',
              url: signedUrl,
            });

            return cdnImageResponse.data;
          }
          // This image is not from discover - we use game_images endpoints
          if (isAuthenticated === true && isOwner) {
            return this.retryWithFreshTokenIf401(async token => {
              const url = `${this.url}/v0.5/game_images/filesV2/${fileId}${
                query ? query : ''
              }`;
              const res = await axios.request<string>({
                method: 'get',
                url,
                headers: {
                  ...getAuthHeader(token, getDestinationTenantFromUrl()),
                  ...(attemptNumber > 1 && { 'Cache-Control': 'no-cache' }),
                },
              });

              const signedUrl = res.data;

              //we want to prefetch the image so also get the image blob from cdn
              const cdnImageResponse = await axios.request({
                method: 'get',
                url: signedUrl,
              });

              return cdnImageResponse.data;
            });
          } else {
            const url = `${this.url}/v0.5/game_images/public/filesV2/${fileId}${
              query ? query : ''
            }`;

            const res = await axios.request<string>({
              method: 'get',
              url,
              headers: {
                ...getUnAuthHeader(getDestinationTenantFromUrl()),
                ...(attemptNumber > 1 && { 'Cache-Control': 'no-cache' }),
              },
            });

            const signedUrl = res.data;

            //we want to prefetch the image so also get the image blob from cdn
            const cdnImageResponse = await axios.request({
              method: 'get',
              url: signedUrl,
            });

            return cdnImageResponse.data;
          }
        },
        {
          retries: 3,
        },
      ),

    getGifImage: async (fileId: string) => {
      const downloadRes = await this.getDownloadUrl(fileId);
      const url = downloadRes.data.url;
      const res = await Axios.request<Blob>({
        method: 'get',
        url,
        responseType: 'blob',
      });
      const objUrl = URL.createObjectURL(res.data);
      return objUrl;
    },
  };

  paywall = {
    retrieveCredits: async (activeTenantId?: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<{ translationCredits: number }>(
          `${this.url}/v0.5/credits`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)),
          },
        );
        return res.data;
      });
    },
  };

  template = {
    translateSlides: async ({
      originLanguage,
      outputLanguage,
      slides,
      activeTenantId,
    }: {
      slides: Slide[];
      originLanguage: string;
      outputLanguage: string;
      activeTenantId?: string;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<Slide[]>(
          `${this.url}/v0.5/templates/translateSlides`,
          { slides, originLanguage, outputLanguage },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)),
            timeout: 1000 * slides.length * 75,
          },
        );
        return res.data;
      });
    },
    duplicateTemplate: async ({
      parentId,
      templateId,
      activeTenantId,
      allowGeneratorPresentation,
      isPrivate,
    }: {
      templateId: string;
      parentId: string | null;
      isPrivate?: boolean;
      activeTenantId?: string;
      allowGeneratorPresentation?: boolean;
    }) => {
      const tenantId = getDestinationTenantFromUrl();
      return this.retryWithFreshTokenIf401(async token => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const res = await axios.post<{ template: Template; errors: any[] }>(
          `${this.url}/v0.5/templates/copyTemplate`,
          { templateId, tenantId, parentId, isPrivate, allowGeneratorPresentation },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)),
          },
        );
        return res.data;
      });
    },

    retrieveTemplate: (templateId: string): Promise<Template | undefined> => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<Template | undefined>(
          `${this.url}/v0.5/templates/${templateId}`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    listTemplates: (parentId?: string | null, tenantId?: string): Promise<Template[]> => {
      let queryString = '';
      if (parentId) {
        queryString += '?parentId=' + parentId;
      }
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<Template[]>(
          `${this.url}/v0.5/templates${queryString}`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl(tenantId)),
          },
        );
        return res.data;
      });
    },

    listRecentTemplates: ({
      limit,
      cursor,
      tenantId,
      isPrivate,
    }: {
      limit: number;
      tenantId?: string;
      cursor?: number;
      isPrivate?: boolean;
    }): Promise<{ items: Template[]; cursor: string }> => {
      return this.retryWithFreshTokenIf401(async token => {
        const searchParams = new URLSearchParams();
        searchParams.set('limit', limit.toString());
        if (isPrivate !== undefined) {
          searchParams.set('isPrivate', isPrivate.toString());
        }
        const queryString = `${searchParams.toString()}`;

        let url = `${this.url}/v0.5/templates/recent?${queryString}`;
        if (cursor) url = url + `&cursor=${cursor}`;
        const res = await axios.get<{ items: Template[]; cursor: string }>(url, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl(tenantId)),
        });
        return res.data;
      });
    },
    listDeletedTemplates: (): Promise<Template[]> => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<Template[]>(`${this.url}/v0.5/templates/deleted`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },
    listFolders: (tenantId?: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<Folder[]>(`${this.url}/v0.5/folders`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl(tenantId)),
        });
        return res.data;
      });
    },
    listDeletedFolders: () => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<Folder[]>(`${this.url}/v0.5/folders/deleted`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },

    newFolder: (data: FolderEditOrCreateDTO) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<Folder>(`${this.url}/v0.5/folders`, data, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },
    saveFolder: (changes: FolderEditOrCreateDTO) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.put<Folder>(
          `${this.url}/v0.5/folders/${changes.id}`,
          { changes: changes },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    restoreFolder: (folderId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.put<Folder>(
          `${this.url}/v0.5/folders/${folderId}/restore`,
          {},
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    templateAutoSave: async (
      data?: TemplateEditOrCreateDTO | null,
      activeTenantId?: string,
    ) => {
      // Make sure we pass in undefined if the data.id = "new";
      const id = data?.id === NEW_TEMPLATE_PARAM ? undefined : data?.id;

      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.put<Template>(
          `${this.url}/v0.5/templates`,
          {
            ...data,
            id,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)),
          },
        );
        return res.data;
      });
    },
    restoreTemplate: async (templateId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.put<Template>(
          `${this.url}/v0.5/templates/${templateId}/restore`,
          {},
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },

    deleteTemplate: (templateId: string): Promise<string> => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.delete(`${this.url}/v0.5/templates/${templateId}`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },
    deleteFolder: (folderId: string): Promise<string[]> => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.delete(`${this.url}/v0.5/folders/${folderId}`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },
    createGameFromTemplate: ({
      templateId,
      isFromDiscover,
      title,
      activeTenantId,
      slides,
      requireRealNames,
      currentRound,
      metadata,
      attachments,
    }: {
      templateId?: string;
      title?: string | null;
      requireRealNames: boolean;
      isFromDiscover?: boolean;
      activeTenantId?: string;
      slides: Slide[];
      currentRound?: string | null;
      metadata?: ById<string | boolean | number> | null;
      attachments?: LessonAttachment[];
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<Game>(
          `${this.url}/v0.5/templates/createLivePresentation`,
          {
            gameLanguage: i18n?.language,
            templateId,
            isFromDiscover: isFromDiscover,
            slides,
            title: title,
            requireRealNames: requireRealNames,
            currentRound,
            metadata,
            attachments,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)),
          },
        );
        return res.data;
      });
    },
  };
  game = {
    kickParticipant: async ({
      gameId,
      participantId,
    }: {
      gameId: string;
      participantId: string;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post(
          `${this.url}/v0.5/games/${gameId}/kickParticipant`,
          { participantId },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },

    retrievePoll: async ({ gameId, roundId }: { gameId: string; roundId: string }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<RoundOptions[]>(
          `${this.url}/v0.5/games/${gameId}/slides/${roundId}/poll`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    retrievePublicPoll: async ({
      gameId,
      roundId,
    }: {
      gameId: string;
      roundId: string;
    }) => {
      const res = await axios.get<RoundOptions[]>(
        `${this.url}/v0.5/games/public/${gameId}/slides/${roundId}/poll`,
        {
          headers: getUnAuthHeader(getDestinationTenantFromUrl()),
        },
      );
      return res.data;
    },
    retrievePublicResponses: async ({
      gameId,
      roundId,
    }: {
      gameId: string;
      roundId: string;
    }) => {
      const res = await axios.get<Response[]>(
        `${this.url}/v0.5/games/public/${gameId}/slides/${roundId}/responses`,
        {
          headers: getUnAuthHeader(getDestinationTenantFromUrl()),
        },
      );
      return res.data;
    },
    retrievePublicAiFeedback: async ({
      gameId,
      slideId,
    }: {
      gameId: string;
      slideId: string;
    }) => {
      const res = await axios.get<{ results: AiAssessedScore[] }>(
        `${this.url}/v0.5/games/public/${gameId}/slides/${slideId}/listAiAssessments`,
        {
          headers: getUnAuthHeader(getDestinationTenantFromUrl()),
        },
      );
      return res.data;
    },
    retrieveAllAIFeedbackForGame: async ({ gameId }: { gameId: string }) => {
      const res = await axios.get<{
        items: { aiAssessedScores: AiAssessedScore[]; slideId: string }[];
      }>(`${this.url}/v0.5/games/public/${gameId}/listAllAIFeedback`, {
        headers: getUnAuthHeader(getDestinationTenantFromUrl()),
      });
      return res.data;
    },
    retrieveWordcloudResults: async ({
      gameId,
      slideId,
    }: {
      gameId: string;
      slideId: string;
    }) => {
      /**@deprecated */
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<Score[]>(
          `${this.url}/v0.5/games/${gameId}/slides/${slideId}/wordcloudresults`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );

        return res.data;
      });
    },
    retrieveWordcloud: async ({
      gameId,
      slideId,
    }: {
      gameId: string;
      slideId: string;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<Score[]>(
          `${this.url}/v0.5/games/${gameId}/slides/${slideId}/wordcloud`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );

        return res.data;
      });
    },
    retrievePublicWordcloud: async ({
      gameId,
      slideId,
    }: {
      gameId: string;
      slideId: string;
    }) => {
      const res = await axios.get<Score[]>(
        `${this.url}/v0.5/games/public/${gameId}/slides/${slideId}/wordcloud`,
        {
          headers: getUnAuthHeader(getDestinationTenantFromUrl()),
        },
      );

      return res.data;
    },
    initVotingRound: async (slideId: string, gameId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<{ path: string }>(
          `${this.url}/v0.5/games/${gameId}/slides/${slideId}/initVoting`,
          {},
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    retrieveResults: async (gameId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<ActivityResults>(
          `${this.url}/v0.5/games/${gameId}/results`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    autoSave: async (data: GameEditOrCreateDTO) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<Game>(
          `${this.url}/v0.5/games/autoSave`,
          {
            ...data,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },

    addNewActivity: async (data: {
      gameId: string;
      type: RoundType;
      title?: string | null;
      votingTitle?: string | null;
      hasVoting: boolean;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        await axios.post(
          `${this.url}/v0.5/games/${data.gameId}/addActivity`,
          {
            ...data,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
      });
    },

    dismissQuestion: ({
      responseId,
      roundId,
      gameId,
      dismiss,
    }: {
      responseId: string;
      roundId: string;
      gameId: string;
      dismiss: boolean;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<void>(
          `${this.url}/v0.5/games/${gameId}/slides/${roundId}/responses/${responseId}/dismiss`,
          { dismiss },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    divideIntoGroups: ({ groupSize, gameId }: { groupSize: number; gameId: string }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<void>(
          `${this.url}/v0.5/games/${gameId}/groups`,
          { groupSize },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    generateNewPin: (gameId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<Game>(
          `${this.url}/v0.5/games/${gameId}/init`,
          {},
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },

    exportCSV: (roundId: string, gameId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<{ url: string }>(
          `${this.url}/v0.5/games/${gameId}/exportCSV/${roundId}`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    exportExcelResults: (gameId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<{ url: string }>(
          `${this.url}/v0.5/games/${gameId}/export`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    duplicateGame: (gameId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<Game>(
          `${this.url}/v0.5/games/${gameId}/duplicate`,
          {},
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },

    createPreviewGame: (
      templateId: string,
      isDemoGame: boolean,
      isFromDiscover: boolean,
      activeTenantId?: string,
    ) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<Game>(
          `${this.url}/v0.5/templates/${templateId}/preview${
            isDemoGame ? '?isDemoGame=true' : ''
          }`,
          { isFromDiscover: isFromDiscover },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)),
          },
        );
        return res.data;
      });
    },
    deleteGame: (gameId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.delete(`${this.url}/v0.5/games/${gameId}`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },

    listResults: (limit: number, cursor?: number, templateId?: string | null) => {
      return this.retryWithFreshTokenIf401(async token => {
        let url = `${this.url}/v0.5/games/paginatedResults?${
          templateId ? `templateId=${templateId}` : ''
        }&limit=${limit}`;
        if (cursor) url = url + `&cursor=${cursor}`;
        const res = await axios.get<PaginatedGameResults>(url, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });

        return res.data;
      });
    },
    listResponsesForRound: ({ gameId, roundId }: { gameId: string; roundId: string }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<Response[]>(
          `${this.url}/v0.5/games/${gameId}/rounds/${roundId}/listResponses`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    retrieveLeaderBoard: (slideId: string, gameId: string, includeAll?: boolean) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<LeaderBoard>(
          `${this.url}/v0.5/games/${gameId}/leaderboard/${slideId}${
            includeAll ? '?includeAll=true' : ''
          }`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    retrievePublicLeaderBoard: async (
      slideId: string,
      gameId: string,
      includeAll?: boolean,
    ) => {
      const res = await axios.get<LeaderBoard>(
        `${this.url}/v0.5/games/public/${gameId}/leaderboard/${slideId}${
          includeAll ? '?includeAll=true' : ''
        }`,
        {
          headers: getUnAuthHeader(getDestinationTenantFromUrl()),
        },
      );
      return res.data;
    },
    retrieve: (gameId?: string | undefined) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<Game>(`${this.url}/v0.5/games/${gameId}`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },

    end: (gameId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<void>(
          `${this.url}/v0.5/games/${gameId}/endGame`,
          {},
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
            timeout: 30 * 1000,
          },
        );
        return res.data;
      });
    },
    setAllRoundsAsCompleted: (gameId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<Game>(
          `${this.url}/v0.5/games/${gameId}/setAllRoundsAsCompleted`,
          {},
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    editTitle: ({ gameId, title }: { gameId: string; title: string }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<Game>(
          `${this.url}/v0.5/games/${gameId}/editTitle`,
          {
            title,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
  };

  feedback = {
    submit: async (message: string, activeTenantId: string, accepted?: boolean) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post(
          `${this.url}/v0/feedback`,
          {
            message,
            accepted,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)), //This might not be needed for a feedback
          },
        );
        return res.data;
      });
    },
  };
  collection = {
    listAllCollections: async (activeTenantId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<Collection[]>(`${this.url}/v0.5/collection`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)),
        });
        return res.data;
      });
    },
    getSingleCollection: async (collectionId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<Collection>(
          `${this.url}/v0.5/collection/${collectionId}`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    createNewCollection: async (activeTenantId: string, collectionName: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<Collection>(
          `${this.url}/v0.5/collection/createCollection`,
          { collectionName },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)),
          },
        );
        return res.data;
      });
    },
    renameCollection: async (
      activeTenantId: string,
      collectionName: string,
      collectionId: string,
    ) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.put<Collection>(
          `${this.url}/v0.5/collection/${collectionId}`,
          { collectionName },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)),
          },
        );
        return res.data;
      });
    },
    deleteCollection: async (activeTenantId: string, collectionId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.delete(`${this.url}/v0.5/collection/${collectionId}`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)),
        });
        return res.data;
      });
    },
    addToMyCollections: async (collectionId: string, activeTenantId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<Collection>(
          `${this.url}/v0.5/collection/addToMyCollections`,
          { collectionId },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)),
          },
        );
        return res.data;
      });
    },
    addGeneratorToCollection: async (
      activeTenantId: string,
      collectionId: string,
      generatorId: string,
    ) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<Collection>(
          `${this.url}/v0.5/collection/${collectionId}/addGeneratorToCollection`,
          { generatorId },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)),
          },
        );
        return res.data;
      });
    },
    removeGeneratorFromCollection: async (
      activeTenantId: string,
      collectionId: string,
      generatorId: string,
    ) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.put<Collection>(
          `${this.url}/v0.5/collection/${collectionId}/removeGeneratorFromCollection`,
          { generatorId },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)),
          },
        );
        return res.data;
      });
    },
  };
  quote = {
    //Unauthenticated endpoints
    getQuote: async (quoteId: string) => {
      const res = await axios.get<QuoteAndUsage>(`${this.url}/v0.5/quote/${quoteId}`);
      return res.data;
    },
    extendQuote: async (quoteId: string) => {
      const res = await axios.post<Quote>(`${this.url}/v0.5/quote/${quoteId}/extend`);
      return res.data;
    },
    //Authenticated endpoints
    getMyQuote: async () => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<QuoteAndUsage>(`${this.url}/v0.5/quote/me`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },
    createMyQuote: async ({
      pdmName,
      pdmEmail,
    }: {
      pdmName: string;
      pdmEmail: string;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<QuoteAndUsage>(
          `${this.url}/v0.5/quote/me/create`,
          { pdmName, pdmEmail },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
  };

  standards = {
    fetchAllStandards: async () => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<Standards[]>(`${this.url}/v0.5/standards`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },
    createStandard: async ({
      label,
      description,
    }: {
      label: string;
      description: string;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<QuoteAndUsage>(
          `${this.url}/v0.5/standards/create`,
          { label, description },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
  };

  districtData = {
    getDistrictData: async () => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<DistrictUsageData[]>(
          `${this.url}/v0.5/districtUsage/data`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    getSchoolData: async ({ tenantId }: { tenantId?: string }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<SchoolUsageData[]>(
          `${this.url}/v0.5/districtUsage/schoolData?schoolTenantId=${tenantId}`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
  };

  moderation = {
    join: async (pin: string, activeTenantId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<{ id: string; tenantId: string }>(
          `${this.url}/v0.5/moderation/join`,
          { pin },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)),
          },
        );
        return res.data;
      });
    },
  };

  export = {
    createToken: async ({
      activeTenantId,
      email,
    }: {
      activeTenantId?: string;
      email?: string;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<{ token: string }>(
          `${this.url}/v0.5/export/createToken`,
          {
            email,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)), //This might not be needed for a feedback
          },
        );
        return res.data;
      });
    },
    checkToken: async ({
      activeTenantId,
      token: tokenToVerify,
    }: {
      activeTenantId?: string;
      token: string;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<{
          verified: true;
          payload: ExportTokenPayload;
          tenantName: string;
        }>(
          `${this.url}/v0.5/export/checkToken`,
          {
            token: tokenToVerify,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)), //This might not be needed for a feedback
          },
        );
        return res.data;
      });
    },
    execute: async ({
      activeTenantId,
      token: tokenToVerify,
    }: {
      activeTenantId?: string;
      token: string;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post(
          `${this.url}/v0.5/export/execute`,
          {
            token: tokenToVerify,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl(activeTenantId)), //This might not be needed for a feedback
          },
        );
        return res.data;
      });
    },
  };

  presentations = {
    search: async (query: PresentationQuery) => {
      const searchParams = new URLSearchParams();

      searchParams.set('search', query.search);
      searchParams.set('isPrivate', query.isPrivate.toString());

      if (query.parentFolderId) {
        searchParams.set('parentFolderId', query.parentFolderId);
      }
      if (query.limit) {
        searchParams.set('limit', query.limit.toString());
      }
      if (query.cursor) {
        searchParams.set('cursor', query.cursor.toString());
      }

      const queryString = query ? `${searchParams.toString()}` : '';
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<{
          items: PresentationDTO[];
          cursor: number;
          total: number;
        }>(`${this.url}/v0.5/presentations/search?${queryString}`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()), //This might not be needed for a feedback
        });
        return res.data;
      });
    },
    list: async (query: {
      parentId?: string;
      isPrivate: boolean;
      limit?: number;
      cursor?: string;
      orderBy?: 'modifiedAt' | 'title';
      sortDirection?: 'asc' | 'desc';
    }) => {
      const searchParams = new URLSearchParams();
      searchParams.set('isPrivate', query.isPrivate.toString());

      if (query.parentId) {
        searchParams.set('parentId', query.parentId);
      }
      if (query.limit) {
        searchParams.set('limit', query.limit.toString());
      }
      if (query.cursor) {
        searchParams.set('cursor', query.cursor.toString());
      }
      if (query.orderBy) {
        searchParams.set('orderBy', query.orderBy.toString());
      }
      if (query.sortDirection) {
        searchParams.set('sortDirection', query.sortDirection.toString());
      }

      const queryString = query ? `?${searchParams.toString()}` : '';
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<{ items: PresentationDTO[]; cursor: string }>(
          `${this.url}/v0.5/presentations/${queryString}`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()), //This might not be needed for a feedback
          },
        );
        return res.data;
      });
    },

    createLessonFromSample: async ({
      presentationId,
      slides,
      tenantId,
      isPresented,
    }: {
      presentationId: string;
      slides: Slide[];
      tenantId: string;
      isPresented?: boolean;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<{
          presentation: PresentationDTO;
          livePresentationId?: string;
        }>(
          `${this.url}/v0.5/presentations/create-lesson-from-sample`,
          { presentationId, slides, isPresented },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl(tenantId)),
          },
        );
        return res.data;
      });
    },
    createAttachment: async ({ title, content }: { title: string; content: string }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<LessonAttachment>(
          `${this.url}/v0.5/presentations/attachments`,
          { title, content },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    editAttachment: async ({
      title,
      content,
      attachmentId,
    }: {
      title: string;
      content: string;
      attachmentId: string;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.put<LessonAttachment>(
          `${this.url}/v0.5/presentations/attachments/${attachmentId}`,
          { title, content },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    deleteAttachment: async ({ attachmentId }: { attachmentId: string }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.delete<LessonAttachment>(
          `${this.url}/v0.5/presentations/attachments/${attachmentId}`,

          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
  };
  integrations = {
    getMetadata: async (
      integrationLabel: SupportedIntegrationLabel,
    ): Promise<SupportedIntegrationResponse> => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get(`${this.url}/v0.5/integrations/${integrationLabel}`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },
    refreshMetadata: async (
      integrationLabel: SupportedIntegrationLabel,
    ): Promise<SupportedIntegrationResponse> => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post(
          `${this.url}/v0.5/integrations/${integrationLabel}`,
          {},
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    deleteMetadata: async (
      integrationLabel: SupportedIntegrationLabel,
    ): Promise<SupportedIntegrationResponse> => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.delete(
          `${this.url}/v0.5/integrations/${integrationLabel}`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    googleDrive: {
      copyFileToBucket: async (
        fileId: string,
      ): Promise<{ filePath: string; fileType: string }> => {
        return this.retryWithFreshTokenIf401(async token => {
          const res = await axios.post(
            `${this.url}/v0.5/integrations/${SupportedIntegrationLabel.GoogleDrive}/copyFileToBucket`,
            { fileId: fileId },
            {
              headers: getAuthHeader(token, getDestinationTenantFromUrl()),
            },
          );
          return res.data;
        });
      },
    },
  };
}
export type DiscoverExploreTemplates = {
  data: ById<IndexedTemplate[]>;
  orderedGroups: { id: string; name: string }[];
};

export type ExportTokenPayload = {
  tenantId: string;
  uid: string;
  issuedBy?: string;
  // token fields
  iat: number;
  exp: number;
  aud: string;
  iss: string;
};

export type LookupTenant = { tenantId: string; tenantName: string };

export type PresentationQuery = {
  search: string;

  isPrivate: boolean; // Optional to support workspace search
  parentFolderId?: string; // Optional to support folder search

  cursor?: number;
  limit: number;
};

export type LessonAttachment = {
  attachmentId: string;
  type: 'passage';
  title: string;
  fileId: string;
  fileMimetype: 'application/json';
  modifiedAt: number;
};
