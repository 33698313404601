import { LoadingOutlined } from '@ant-design/icons';
import { useFlag } from '@unleash/proxy-client-react';
import { Divider, Drawer, Dropdown, Menu, Modal } from 'antd';
import MenuItem from 'antd/lib/menu/MenuItem';
import { AnimatePresence, motion } from 'framer-motion';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { signOut, useSession } from 'next-auth/react';
import { ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useIntercom } from 'react-use-intercom';
import styled, { createGlobalStyle, css } from 'styled-components';

import {
  CrossSvg,
  LogoSvg,
  MenuArrowDownSvg,
  MenuSvg,
  PlusSvg,
  QuestionMarkSvg,
  SignOutSvg,
  UserSvg,
} from '../../assets/icons';
import { BlankLessonSvg } from '../../assets/icons/BlankLessonSvg';
import BookmarkSvg from '../../assets/icons/BookmarkSvg';
import ChartSvg from '../../assets/icons/ChartSvg';
import CuripodDevSvg from '../../assets/icons/CuripodDevLogo';
import CuripodNormal from '../../assets/icons/CuripodNormal';
import FolderSvg from '../../assets/icons/FolderSvg';
import GroupSvg from '../../assets/icons/GroupSvg';
import HomeSvg from '../../assets/icons/HomeSvg';
import { MagicWandSvg } from '../../assets/icons/MagicWandSvg';
import PriceSvg from '../../assets/icons/PriceSvg';
import UsageGraphSvg from '../../assets/icons/UsageGraphSvg';
import theme from '../../assets/theme';
import { useBreakPoint } from '../../hooks/useBreakPoint';
import useMe from '../../hooks/useMe';
import { useMetrics } from '../../hooks/useMetrics';
import { useOnOutsideClick } from '../../hooks/useOnOutsideClick';
import useRouterMatch from '../../hooks/useRouterMatch';
import useSignOut from '../../hooks/useSignOut';
import useTenant from '../../hooks/useTenant';
import useTenants from '../../hooks/useTenants';
import useWorkSpace from '../../hooks/useWorkSpace';
import { NewAnonymisedTopbar } from '../../pages/discover/index_deprecated';
import AuthGuardProvider from '../../providers/AuthGuardProvider';
import {
  useHideIntercom,
  useIsIntercomOpen,
} from '../../providers/CustomIntercomStateProvider';
import { ById } from '../../services/database/types';
import { isProduction } from '../../utils/client';
import { getDestinationTenantFromUrl } from '../../utils/navigator';
import { TenantLicense } from '../../utils/tenantPlanUtils';
import { zIndexes } from '../../utils/zIndexes';
import { Button } from '../_atoms/Button';
import DropDownButton, {
  DropdownButtonItem,
} from '../_atoms/DropdownButton/DropdownButton';
import { IconWrapper } from '../_atoms/IconWrapper';
import ProfileDefault from '../_atoms/ProfileDefault';
import AnonymisedMobileTopbar from '../_molecules/AnonymisedMobileTopbar';
import SmartProfile from '../_molecules/SmartProfile';
import CreateTeam from '../_organisms/CreateTeam/CreateTeam';
import InvitationModal from '../_organisms/InvitationModal/InvitationModal';
import NewLiveSideBar from '../_organisms/NewLiveSideBar';
import { TenantsMenuItem } from '../_organisms/NewLiveSideBar/NewLiveSideBar';
import NickNameModal from '../_organisms/NickNameModal';
import HotjarSnippet from '../analytics/HotJar';
import { BaseTransition } from '../styled';

export default function NewAppLayout({
  children,
  onBackClick,
  allowUnAuthenticated,
  scrollOffset,
  breakPoint = 800,
}: {
  children: ReactNode;
  onBackClick?: () => void;
  allowUnAuthenticated?: boolean;
  scrollOffset?: number;
  breakPoint?: number;
}) {
  const isDesktop = useBreakPoint(breakPoint);
  const session = useSession();
  const router = useRouter();
  const isLoading = session.status === 'loading';
  const isAuthenticated = session.status === 'authenticated';

  return (
    <>
      <AuthGuardProvider allowUnAuthenticated={allowUnAuthenticated}>
        <>
          {isDesktop && isAuthenticated && <NewTopBar />}
          {!isDesktop && isAuthenticated && (
            <>
              <NewMobileTopBar onBackClick={onBackClick} scrollOffset={scrollOffset} />
              <BottomBar />
            </>
          )}
          {!isDesktop && !isAuthenticated && (
            <AnonymisedMobileTopbar
              close={() => router.back()}
              isLoadingAuth={isLoading}
            />
          )}
          {isDesktop && !isAuthenticated && (
            <NewAnonymisedTopbar isLoadingAuth={isLoading} />
          )}

          {children}
        </>
      </AuthGuardProvider>
      <HotjarSnippet />
    </>
  );
}

const CreateLessonMenuItem = () => {
  const { router, tenantId, parentFolderId, workspaceId } = useRouterMatch();
  const metrics = useMetrics();
  const isNewCreatePageEnabled = useFlag('enableNewCreatePage');

  const { trackEvent } = useIntercom();
  const createLessonLink = useMemo(() => {
    if (parentFolderId && workspaceId)
      return `/${tenantId}/lessons/new/edit?workspaceId=${workspaceId}&parentFolderId=${parentFolderId}`;
    if (workspaceId) return `/${tenantId}/lessons/new/edit?workspaceId=${workspaceId}`;
    if (parentFolderId)
      return `/${tenantId}/lessons/new/edit?parentFolderId=${parentFolderId}`;
    return `/${tenantId}/lessons/new/edit`;
  }, [parentFolderId, tenantId, workspaceId]);

  const handleCreateTemplate = () => {
    metrics.logEvent('Lessons.NewTemplate.Start', {
      placement: 'topbar',
      path: router.pathname,
      workspaceId: workspaceId,
    });
    trackEvent('PresentationCreated');
  };

  if (isNewCreatePageEnabled) {
    return (
      <>
        <DropDownButton
          isCreateLessonButton={true}
          icon={<MagicWandSvg />}
          menu={
            <Menu>
              <Menu.Item key="create" onClick={() => router.push(`/${tenantId}/create`)}>
                <DropdownButtonItem
                  isDarkTheme={false}
                  icon={
                    <div
                      style={{
                        borderRadius: 50,
                        background: theme.colors.orange,
                        color: 'white',
                        height: 40,
                        width: 40,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <IconWrapper height="16px" width="16px">
                        <MagicWandSvg />
                      </IconWrapper>
                    </div>
                  }
                  title={'Create lesson with AI'}
                  description={'Aligned to skills, content and standards'}
                />
              </Menu.Item>
              <Menu.Item key="createblank" onClick={() => router.push(createLessonLink)}>
                <DropdownButtonItem
                  isDarkTheme={false}
                  icon={
                    <div
                      style={{
                        borderRadius: 50,
                        background: theme.colors.green,
                        color: 'white',
                        height: 40,
                        width: 40,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <IconWrapper height="16px" width="16px">
                        <BlankLessonSvg />
                      </IconWrapper>
                    </div>
                  }
                  title={'Create blank lesson'}
                  description={
                    'Design your lesson from scratch with interactive question types'
                  }
                />
              </Menu.Item>
            </Menu>
          }
          onClick={() => router.push(`/${tenantId}/create`)}
          style={{
            background: theme.colors.orange,
            color: theme.colors.white,
            height: 43,
            marginRight: 10,
          }}
        >
          Get Lesson
        </DropDownButton>
      </>
    );
  }

  return (
    <>
      {createLessonLink && (
        <AILessonsNavMenuLinkItem
          key="ai"
          icon={<MagicWandSvg />}
          text={'Get Lesson'}
          link={createLessonLink}
          onClick={handleCreateTemplate}
        />
      )}
    </>
  );
};

const about = [
  { text: 'Our Learning Principles', link: '/learning-principles' },
  { text: 'About us', link: '/about-us' },
  {
    text: "We're hiring",
    link:
      'https://curipod.notion.site/The-Curipod-work-Experience-9a732ddf5a904f6abac84d55a733fc02?pvs=4',
    external: true,
  },
  {
    text: 'Merchandise store',
    link: 'https://shop.curipod.com/',
    external: true,
  },
];
const solutionsOther = [
  {
    text: 'Case study',
    link: 'https://curipod.com/Curipod_Case_Study_South_Texas.pdf',
    external: true,
  },
  { text: 'All customizable lessons', link: '/customizable-lessons', external: false },
  {
    text: 'Moderation tool',
    link: '/c/how-to-moderate-inappropriate-students-in-curipod',
    external: false,
  },
  // { text: 'ACT', link: '/staar-test-prep', external: false },
];

const elaPractice = [
  { text: 'Argumentative writing', link: '/c/argumentative-writing', external: false },
  { text: 'Narrative writing', link: '/c/narrative-writing', external: false },
  { text: 'Informational writing', link: '/c/informational-writing', external: false },
];
const testPrep = [
  {
    text: 'Case study',
    link: 'https://curipod.com/Curipod_Case_Study_South_Texas.pdf',
    external: true,
  },
  { text: 'STAAR test prep', link: '/c/staar-test-prep', external: false },
  { text: 'CAASPP test prep', link: '/c/caaspp-test-prep', external: false },
  { text: 'GMAS test prep', link: '/c/gmas-test-prep', external: false },
  { text: 'PSSA test prep', link: '/c/pssa-test-prep', external: false },
  { text: 'M-STEP test prep', link: '/c/m-step-test-prep', external: false },
  { text: 'NC State Tests', link: '/c/nc-state-test-prep', external: false },
  { text: 'Ohio State Tests', link: '/c/ohio-state-test-prep', external: false },
];

const support = [
  { text: 'Connectivity issues', link: '/connectivity-issues', external: false },
];

const inspiration = [
  {
    text: 'PD resources',
    link: 'https://wakelet.com/wake/_Gp7EAdlbjvOmNAyX7m5w',
    external: true,
  },
  { text: 'Webinars and Events', link: '/c/events' },
  { text: 'Blog', link: '/blog' },
  {
    text: 'Curicon 2023 - replays',
    link:
      'https://www.canva.com/design/DAFuuziK1yM/SWx7Y1ia8p9cefjFzoD2bg/view?utm_content=DAFuuziK1yM&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink#16',
    external: true,
  },
  {
    text: 'Curipod Courses',
    link: '/c/courses',
  },
  { text: 'Teacher community', link: '/community' },
  { text: 'Ambassador program', link: '/c/ambassadors', external: false },
];

const compliance = [
  { text: 'AI in curipod', link: '/ai-in-curipod' },
  { text: 'GDPR', link: '/gdpr' },
  { text: 'Privacy policy', link: '/privacy-policy' },
  {
    text: 'State and district privacy agreements',
    link: '/state-and-district-privacy-agreements',
  },
  { text: 'Terms of use', link: '/terms-of-use' },
  { text: 'Accessibility', link: '/accessibility', external: false },
];

export const TopBarDropdowns = ({
  isLandingPage,
  isRightAligned,
}: {
  isLandingPage?: boolean;
  isRightAligned?: boolean;
}) => {
  const { t } = useTranslation('Host');
  const { show } = useIntercom();
  return (
    <>
      <GlobalStyles $isLandingPage={isLandingPage} $isRightAligned={isRightAligned} />
      <Dropdown
        overlayClassName="color-dropdown"
        overlay={
          <Menu>
            <MenuContainer $isLandingPage={isLandingPage}>
              <DropdownMenuColumn>
                <h3>Test prep</h3>
                {testPrep.map(item => (
                  <MenuItem key={item.text}>
                    <Link href={item.link} passHref>
                      {item.external ? (
                        <a target="__blank" rel="noopener noreferrer">
                          {item.text}
                        </a>
                      ) : (
                        item.text
                      )}
                    </Link>
                  </MenuItem>
                ))}
              </DropdownMenuColumn>
              <Divider type="vertical" />
              <DropdownMenuColumn>
                <h3>Reading and writing practice</h3>
                {elaPractice.map(item => (
                  <MenuItem key={item.text}>
                    <Link href={item.link} passHref>
                      {item.external ? (
                        <a target="__blank" rel="noopener noreferrer">
                          {item.text}
                        </a>
                      ) : (
                        item.text
                      )}
                    </Link>
                  </MenuItem>
                ))}
              </DropdownMenuColumn>
              <Divider type="vertical" />

              <DropdownMenuColumn>
                <h3>Other</h3>
                {solutionsOther.map(item => (
                  <MenuItem key={item.text}>
                    <Link href={item.link} passHref>
                      {item.external ? (
                        <a target="__blank" rel="noopener noreferrer">
                          {item.text}
                        </a>
                      ) : (
                        item.text
                      )}
                    </Link>
                  </MenuItem>
                ))}
              </DropdownMenuColumn>
            </MenuContainer>
          </Menu>
          // <Menu>
          //   <MenuContainer $isLandingPage={isLandingPage}>
          //     <DropdownMenuColumn>
          //       {/* <h3>Test prep</h3> */}
          //       {solutions.map(item => (
          //         <MenuItem key={item.text}>
          //           <Link href={item.link} passHref>
          //             {item.external ? (
          //               <a target="__blank" rel="noopener noreferrer">
          //                 {item.text}
          //               </a>
          //             ) : (
          //               item.text
          //             )}
          //           </Link>
          //         </MenuItem>
          //       ))}
          //       {!isLandingPage && (
          //         <Link href={`https://${configureStore().config.game.url}`} passHref>
          //           <a
          //             style={{
          //               fontWeight: 500,
          //               fontSize: 18,
          //               color: theme.colors.greyLight,
          //               marginRight: 20,
          //             }}
          //             target="__blank"
          //             rel="noopener noreferrer"
          //           >
          //             <Button
          //               styles={{
          //                 borderRadius: '20px',
          //                 padding: '5px 12px',
          //               }}
          //             >
          //               {t('Enter pin')}
          //             </Button>
          //           </a>
          //         </Link>
          //       )}
          //     </DropdownMenuColumn>
          //   </MenuContainer>
          // </Menu>
        }
      >
        <a onClick={e => e.preventDefault()}>
          <Button
            styles={{
              boxShadow: 'none',
              borderRadius: '50px',
              border: 'none',
              color: isLandingPage
                ? theme.colors.landingPageTopbarColor
                : theme.colors.black,
              fontSize: isLandingPage ? '22px' : '16px',
            }}
            type={'ghost'}
            icon={<MenuArrowDownSvg />}
          >
            {t('Solutions')}
          </Button>
        </a>
      </Dropdown>
      <Dropdown
        overlayClassName="color-dropdown"
        placement="bottomCenter"
        overlay={
          <Menu>
            <MenuContainer $isLandingPage={isLandingPage}>
              <DropdownMenuColumn>
                <h3>Support</h3>
                <MenuItem key={'support'} onClick={() => show()}>
                  <span>We want to hear from you</span>
                </MenuItem>
                {support.map(item => (
                  <MenuItem key={item.text}>
                    <Link href={item.link} passHref>
                      {item.external ? (
                        <a target="__blank" rel="noopener noreferrer">
                          {item.text}
                        </a>
                      ) : (
                        item.text
                      )}
                    </Link>
                  </MenuItem>
                ))}
              </DropdownMenuColumn>
              <Divider type="vertical" />
              <DropdownMenuColumn>
                <h3>Inspiration</h3>
                {inspiration.map(item => (
                  <MenuItem key={item.text}>
                    <Link href={item.link} passHref>
                      {item.external ? (
                        <a target="__blank" rel="noopener noreferrer">
                          {item.text}
                        </a>
                      ) : (
                        item.text
                      )}
                    </Link>
                  </MenuItem>
                ))}
              </DropdownMenuColumn>
              <Divider type="vertical" />
              <DropdownMenuColumn>
                <h3>Compliance</h3>
                {compliance.map(item => (
                  <MenuItem key={item.text}>
                    <Link href={item.link} passHref>
                      {item.external ? (
                        <a target="__blank" rel="noopener noreferrer">
                          {item.text}
                        </a>
                      ) : (
                        item.text
                      )}
                    </Link>
                  </MenuItem>
                ))}
              </DropdownMenuColumn>
              <Divider type="vertical" />
              <DropdownMenuColumn>
                <h3>About</h3>
                {about.map(item => (
                  <MenuItem key={item.text}>
                    <Link href={item.link} passHref>
                      {item.external ? (
                        <a target="__blank" rel="noopener noreferrer">
                          {item.text}
                        </a>
                      ) : (
                        item.text
                      )}
                    </Link>
                  </MenuItem>
                ))}
              </DropdownMenuColumn>
            </MenuContainer>
          </Menu>
        }
      >
        <a onClick={e => e.preventDefault()}>
          <Button
            styles={{
              boxShadow: 'none',
              borderRadius: '50px',
              border: 'none',
              color: isLandingPage
                ? theme.colors.landingPageTopbarColor
                : theme.colors.black,
              fontSize: isLandingPage ? '22px' : '16px',
            }}
            type={'ghost'}
            icon={<MenuArrowDownSvg />}
          >
            {t('Resources')}
          </Button>
        </a>
      </Dropdown>
      <Link href="/pricing" passHref>
        <a>
          <Button
            styles={{
              boxShadow: 'none',
              borderRadius: '50px',
              border: 'none',
              color: isLandingPage
                ? theme.colors.landingPageTopbarColor
                : theme.colors.black,
              fontSize: isLandingPage ? '22px' : '16px',
            }}
            type={'ghost'}
          >
            Pricing
          </Button>
        </a>
      </Link>
    </>
  );
};

const NewTopBar = () => {
  const { tenantId } = useRouterMatch();

  return (
    <>
      <NewLiveSideBar />
      <Container>
        <WidthContainer>
          <GroupContainer $includeMargin>
            <Link href={`/${tenantId}/home`}>
              <a>
                <ShortLogo />
              </a>
            </Link>
            <TopBarDropdowns />
          </GroupContainer>
          <GroupContainer>
            <CreateLessonMenuItem />
            <NewProfileMenuItem key="profile" />
            <HelpMenuItem key="help" />
          </GroupContainer>
        </WidthContainer>
      </Container>
    </>
  );
};
const maps: ById<string> = {
  search: 'Search',
  feed: 'Feed',
  profile: 'Profile',
  snippets: 'Snippets',
  queue: 'Answer',
  answer: 'Answer',
  review: 'Review',
  notifications: 'Notifications',
  feedback: 'Feedback',
  lessons: 'My lessons',
  workspace: 'Workspace',
  results: 'My reports',
  reports: 'My reports',
};
const MobilePageTitlesByRoute = (pathname: string) => {
  const nonQueryPathName = pathname.replace(/\?.*/, ''); // removes everything after a `?` sign
  const splittedPath = nonQueryPathName.split('/');
  if (splittedPath.includes('lessons')) return 'My lessons';
  if (splittedPath.includes('collections')) return 'My collections';
  if (splittedPath.includes('usage')) return 'Your school usage';
  if (splittedPath.includes('results')) return 'My reports';
  if (splittedPath.includes('reports')) return 'My reports';
  if (splittedPath.includes('preview')) return 'Back to lesson';
  if (splittedPath.includes('discover')) return 'Discover';
  if (splittedPath.includes('workspace')) return 'Workspace';
  if (splittedPath.includes('templates')) return 'My lessons';
  return maps[splittedPath[splittedPath.length - 1]] as string;
};
const NewMobileTopBar = ({
  onBackClick,
  scrollOffset,
}: {
  onBackClick?: () => void;
  scrollOffset?: number;
}) => {
  const { t } = useTranslation('Host');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { router, tenantId } = useRouterMatch();
  const mobileMenuTitle = t(MobilePageTitlesByRoute(router.asPath));
  const isRoot = router.asPath === `/`;
  const isHome = router.asPath.split('?')[0] === `/${tenantId}/home`;
  const isTransparent =
    scrollOffset !== undefined ? scrollOffset <= 65 && isHome : undefined;
  return (
    <MobileContainer
      style={{
        padding: isTransparent ? '14px 20px 14px 20px' : '5px 15px 5px 15px',
        backgroundColor: isTransparent ? 'transparent' : theme.colors.topbar,
        boxShadow: isTransparent ? 'none' : '0px 4px 4px rgb(0, 0, 0, 0.1)',
      }}
      $isTransparent={isTransparent}
    >
      <RootGroupContainer
        onClick={() => {
          if (isRoot) {
            router.push(`/${tenantId}/home`);
          } else {
            onBackClick ? onBackClick() : router.back();
          }
        }}
      >
        <IconWrapper
          height={'25px'}
          width={'85px'}
          style={{
            color: isTransparent ? theme.colors.white : theme.colors.orange,
          }}
        >
          <LogoSvg />
        </IconWrapper>
      </RootGroupContainer>
      <h1
        style={{
          margin: '0 auto',
          fontSize: '18px',
          fontWeight: 'bold',
        }}
      >
        {mobileMenuTitle}
      </h1>

      <ClickableIconWrapper
        $hasBackground={isTransparent}
        onClick={() => setIsMenuOpen(true)}
      >
        <MenuSvg />
      </ClickableIconWrapper>
      <MobileMenu
        visible={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        tenantId={tenantId}
      />
    </MobileContainer>
  );
};

const MobileCloseContext = React.createContext<{ onClose: () => void }>({
  onClose: () => undefined,
});

const MobileCreateLessonMenuItem = () => {
  const { router, tenantId, parentFolderId, workspaceId } = useRouterMatch();
  const { t } = useTranslation('Host');
  const metrics = useMetrics();
  const isNewCreatePageEnabled = useFlag('enableNewCreatePage');
  const { trackEvent } = useIntercom();

  const createLessonLink = useMemo(() => {
    if (isNewCreatePageEnabled) return `/${tenantId}/create`;
    if (parentFolderId && workspaceId)
      return `/${tenantId}/lessons/new/edit?workspaceId=${workspaceId}&parentFolderId=${parentFolderId}`;
    if (workspaceId) return `/${tenantId}/lessons/new/edit?workspaceId=${workspaceId}`;
    if (parentFolderId)
      return `/${tenantId}/lessons/new/edit?parentFolderId=${parentFolderId}`;
    return `/${tenantId}/lessons/new/edit`;
  }, [parentFolderId, tenantId, workspaceId, isNewCreatePageEnabled]);

  const handleCreateTemplateMobile = () => {
    metrics.logEvent('Lessons.NewTemplate.Start', {
      path: router.pathname,
      placement: 'mobileSidebar',
      workspaceId: workspaceId,
    });
    router.replace(createLessonLink);
    trackEvent('PresentationCreated');
  };
  return (
    <MobileIconLinkContainer
      style={{ height: 42 }}
      onClick={() => {
        handleCreateTemplateMobile();
      }}
      isActive={false}
    >
      <MobileIconWrapper>
        <MagicWandSvg />
      </MobileIconWrapper>{' '}
      <p style={{ marginBottom: 0 }}>Get Lesson</p>
    </MobileIconLinkContainer>
  );
};

type MobileMenuProps = {
  visible: boolean;
  onClose: () => void;
  tenantId: string;
};
const MobileMenu = ({ visible, onClose, tenantId }: MobileMenuProps) => {
  const { me } = useMe();
  const { t } = useTranslation('Host');
  const { tenantPlan } = useTenant() as { tenantPlan: TenantLicense | undefined };
  const { workspace, isFetching } = useWorkSpace();
  const handleSignOut = () => signOut({ callbackUrl: '/' });
  const { router } = useRouterMatch();
  const metrics = useMetrics();
  const [showCreateWorkspacePopup, setShowCreateWorkspacePopup] = useState(false);
  const { isOpen } = useIsIntercomOpen();
  const { show, hide } = useIntercom();
  const handleCreateTeam = () => {
    metrics.logEvent('CreateTeam', { from: 'mobile' });
    setShowCreateWorkspacePopup(true);
  };

  const handleHelpClicked = () => {
    if (isOpen) {
      hide();
    } else {
      show();
    }
  };
  return (
    <Drawer
      placement="right"
      closable={false}
      onClose={onClose}
      visible={visible}
      key="left"
      drawerStyle={{ backgroundColor: theme.colors.paleBeige, color: theme.colors.black }}
    >
      <CreateTeam
        onDone={() => {
          setShowCreateWorkspacePopup(false);
          onClose();
        }}
        visible={showCreateWorkspacePopup}
      />
      <MobileCloseContext.Provider value={{ onClose }}>
        <MobileMenuItemsContainer>
          <div>
            <HeaderMenuContainer key="1">
              <MainLogo />
              <IconClickWrapper onClick={onClose}>
                <CrossSvg />
              </IconClickWrapper>
            </HeaderMenuContainer>
            <TenantsMenuItem />
            <MobileCreateLessonMenuItem />
            <Link href={`/${tenantId}/home`}>
              <a onClick={onClose}>
                <MobileIconLinkContainer
                  isActive={router.asPath.split('?')[0] === `/${tenantId}/home`}
                >
                  <MobileIconWrapper>
                    <HomeSvg />
                  </MobileIconWrapper>{' '}
                  <p style={{ marginBottom: 0 }}>{t('Home')}</p>
                </MobileIconLinkContainer>
              </a>
            </Link>
            <Link href={`/${tenantId}/collections`}>
              <a onClick={onClose}>
                <MobileIconLinkContainer
                  isActive={router.asPath === `/${tenantId}/collections`}
                >
                  <MobileIconWrapper>
                    <BookmarkSvg />
                  </MobileIconWrapper>{' '}
                  <p style={{ marginBottom: 0 }}>{t('My collections')}</p>
                </MobileIconLinkContainer>
              </a>
            </Link>
            <Link href={`/${tenantId}/lessons`}>
              <a onClick={onClose}>
                <MobileIconLinkContainer
                  isActive={router.asPath === `/${tenantId}/lessons`}
                >
                  <MobileIconWrapper>
                    <FolderSvg />
                  </MobileIconWrapper>{' '}
                  <p style={{ marginBottom: 0 }}>{t('My lessons')}</p>
                </MobileIconLinkContainer>
              </a>
            </Link>
            <Link href={`/${tenantId}/reports`}>
              <a onClick={onClose}>
                <MobileIconLinkContainer
                  isActive={router.asPath === `/${tenantId}/reports`}
                >
                  <MobileIconWrapper>
                    <ChartSvg />
                  </MobileIconWrapper>{' '}
                  <p style={{ marginBottom: 0 }}>{t('Reports')}</p>
                </MobileIconLinkContainer>
              </a>
            </Link>
            {(me?.schoolCountry === 'US' ||
              me?.schoolCountry === 'United States of America') && (
              <Link href={`/${tenantId}/usage`}>
                <a onClick={onClose}>
                  <MobileIconLinkContainer
                    isActive={router.asPath === `/${tenantId}/usage`}
                  >
                    <MobileIconWrapper>
                      <UsageGraphSvg />
                    </MobileIconWrapper>{' '}
                    <p style={{ marginBottom: 0 }}>{t('Your school usage')}</p>
                  </MobileIconLinkContainer>
                </a>
              </Link>
            )}
            <Link href={`/${tenantId}/profile`}>
              <a onClick={onClose}>
                <MobileIconLinkContainer
                  isActive={router.asPath === `/${tenantId}/profile`}
                >
                  <MobileIconWrapper>
                    <UserSvg />
                  </MobileIconWrapper>{' '}
                  <p style={{ marginBottom: 0 }}>{t('Profile')}</p>
                </MobileIconLinkContainer>
              </a>
            </Link>
            <Link href={`/pricing`}>
              <a onClick={onClose}>
                <MobileIconLinkContainer isActive={router.asPath === `/pricing`}>
                  <MobileIconWrapper>
                    <PriceSvg />
                  </MobileIconWrapper>{' '}
                  <p style={{ marginBottom: 0 }}>{t('Pricing')}</p>
                </MobileIconLinkContainer>
              </a>
            </Link>
            <div onClick={handleHelpClicked}>
              <MobileIconLinkContainer isActive={false}>
                <MobileIconWrapper>
                  <QuestionMarkSvg />
                </MobileIconWrapper>{' '}
                <p style={{ marginBottom: 0 }}>{t('Help')}</p>
              </MobileIconLinkContainer>
            </div>
            {!workspace &&
              tenantPlan?.isEnterprise() &&
              !tenantPlan?.isTrial() &&
              !isFetching && (
                <MobileIconLinkContainer isActive={false} onClick={handleCreateTeam}>
                  <MobileIconWrapper>
                    <GroupSvg />
                  </MobileIconWrapper>{' '}
                  <p style={{ marginBottom: 0 }}>+ {t('Create team')}</p>
                </MobileIconLinkContainer>
              )}
            {workspace && (
              <>
                <Divider />
                <p
                  style={{
                    marginBottom: 0,
                    fontSize: 14,
                    color: theme.colors.greyDark,
                  }}
                >
                  {t('SHARED LIBRARY')}
                </p>
                <Link href={`/${tenantId}/workspace/${workspace.id}`}>
                  <a onClick={onClose}>
                    <MobileIconLinkContainer
                      isActive={
                        router.asPath === `/${tenantId}/workspace/${workspace.id}`
                      }
                    >
                      <MobileIconWrapper>
                        <GroupSvg />
                      </MobileIconWrapper>{' '}
                      <p style={{ marginBottom: 0 }}>{workspace.title}</p>
                    </MobileIconLinkContainer>
                  </a>
                </Link>
              </>
            )}
          </div>
          <div>
            <div onClick={handleSignOut}>
              <Link href={`/${tenantId}/home`}>
                <a onClick={onClose}>
                  <MobileIconLinkContainer isActive={false}>
                    <MobileIconWrapper>
                      <SignOutSvg />
                    </MobileIconWrapper>{' '}
                    <p style={{ marginBottom: 0 }}>{t('Logout')}</p>
                  </MobileIconLinkContainer>
                </a>
              </Link>
            </div>
          </div>
        </MobileMenuItemsContainer>
      </MobileCloseContext.Provider>
    </Drawer>
  );
};
const ShortLogo = () => {
  return (
    <ShortLogoContainer>
      {!isProduction() ? <CuripodDevSvg /> : <LogoSvg />}
    </ShortLogoContainer>
  );
};
const MainLogo = () => (
  <Link href="/">
    <a
      style={{
        margin: 0,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        marginRight: 30,
      }}
    >
      <ShortLogo />
    </a>
  </Link>
);

const MobileIconWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-right: 20px;
  svg {
    height: 20px;
    width: 20px;
  }
`;

const MobileIconLinkContainer = styled.div<{ isActive: boolean }>`
  width: 100%;
  color: ${theme.colors.black};
  display: flex;
  cursor: pointer;
  border-radius: 20px;
  background-color: ${({ isActive }) => (isActive ? '#F3E8D8' : 'transparent')};
  align-items: center;
  padding: 5px 15px;
  margin-bottom: 10px;
  transition: all 0.2s ease-in;

  & p {
    font-weight: bold;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const RootIconLinkContainer = styled.div`
  position: relative;
  border-radius: 5px;
  height: calc(100% - 12px);
  color: ${theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 80px;
  padding: 0px 15px;
  background-color: ${theme.colors.orange};
  & p,
  a {
    color: ${theme.colors.white};
    margin-top: 2px;
    font-weight: bold;
    font-size: 18px;
  }
  svg {
    height: 26px;
    width: 26px;
    margin-right: 5px;
  }
  transition: background-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

  &:hover {
    background-color: ${theme.colors.orange};
  }
`;

const MenuContainer = styled.div<{ $isLandingPage?: boolean }>`
  display: flex;
  gap: 20px;
  padding: 20px;

  h3 {
    padding-left: 10px;
    font-size: 20px;
  }

  li {
    background-color: ${theme.colors.paleBeige} !important;
    transition: background-color 0.2s ease-in;
    border-radius: 20px;
    a {
      color: ${theme.colors.black};
    }

    &:hover {
      background-color: #f3e8d8 !important;
    }
  }
  .ant-divider-vertical {
    height: 150px;
    align-self: center;
  }

  ${({ $isLandingPage }) =>
    $isLandingPage &&
    css`
      li {
        background-color: ${theme.colors.background} !important;
        a {
          color: ${theme.colors.black};
        }
        span {
          color: ${theme.colors.black};
        }

        &:hover {
          background-color: #00000022 !important;
        }
      }

      h3 {
        color: ${theme.colors.black};
      }
    `}
`;

const IconLinkContainer = styled(RootIconLinkContainer)<{ $isDisabled?: boolean }>`
  cursor: pointer;
  margin-right: 20px !important;
  ${({ $isDisabled }) =>
    $isDisabled &&
    `
  opacity: 0.7;
  cursor: progress;
  `};
`;

const ShortLogoContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 60px;
    width: 80px;
    color: ${theme.colors.orange};
  }
`;

const AILessonsNavMenuLinkItem = ({
  text,
  icon,
  onClick,
  isDisabled,
  isLoading,
  link,
}: {
  text: string;
  icon: React.ReactNode;
  onClick?: () => void;
  link: string;
  isLoading?: boolean;
  isDisabled?: boolean;
}) => {
  const isLargerThanBreakPoint = useBreakPoint(900, 1980);

  return (
    <IconLinkContainer style={{ height: 42 }} onClick={onClick} $isDisabled={isDisabled}>
      <Link href={link as string}>
        <a style={{ display: 'flex', marginTop: 0 }}>
          {!isLoading && <NavIconWrapper>{icon}</NavIconWrapper>}
          {isLoading && (
            <NavIconWrapper>
              <LoadingOutlined />
            </NavIconWrapper>
          )}
          {isLargerThanBreakPoint && (
            <p style={{ marginBottom: 0, fontSize: 16 }}>{text}</p>
          )}
        </a>
      </Link>
    </IconLinkContainer>
  );
};

const NavIconWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 5px;
  margin-left: 5px;
  svg {
    height: 20px;
    width: 20px;
  }
`;

const RootGroupContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Container = styled.div`
  z-index: ${zIndexes('TOPBAR')};
  position: fixed;
  padding: 6px 0px;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.topbar};
  box-shadow: 0px 4px 4px rgb(0, 0, 0, 0.1);
  height: 60px;
  min-height: 60px;
`;

const GlobalStyles = createGlobalStyle<{
  $isLandingPage?: boolean;
  $isRightAligned?: boolean;
}>`
  .ant-dropdown.color-dropdown {
    background-color: transparent !important; 
    position: fixed !important;
    top: 60px !important;
    left: ${({ $isRightAligned, $isLandingPage }) =>
      $isRightAligned && !$isLandingPage ? 'unset' : '130px'} !important;
    right: ${({ $isRightAligned, $isLandingPage }) =>
      $isRightAligned && !$isLandingPage ? '130px' : 'unset'} !important;
    min-height: 200px;
  }
  .color-dropdown .ant-dropdown-menu {
    background-color: ${theme.colors.paleBeige};
  }
  .color-dropdown .ant-dropdown-menu-item:hover {
    background-color: #00000022;
  }

  ${({ $isLandingPage }) =>
    $isLandingPage &&
    css`
      .ant-dropdown.color-dropdown {
        background-color: transparent !important;
        position: fixed !important;
        top: 60px !important;
        min-height: 200px;
      }
      .color-dropdown .ant-dropdown-menu {
        background-color: ${theme.colors.background};
      }
      .color-dropdown .ant-dropdown-menu-item:hover {
        background-color: #00000022;
      }
    `}
    
    ${({ $isRightAligned }) =>
      $isRightAligned
        ? css`
            .ant-dropdown.color-dropdown {
              right: 260px !important;
              left: unset !important;
            }
          `
        : css`
            .ant-dropdown.color-dropdown {
              left: 260px !important;
            }
          `}
    
    }


`;

const DropdownMenuColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  span {
    font-family: Nunito;
    font-weight: bold;
  }

  p {
    font-weight: bold;
    line-height: 22px;
    font-size: 14px;
    margin-bottom: 10px;
  }
  a {
    text-decoration: unset;
    line-height: 20px;
  }
  h3 {
    font-size: 1.17em;
    line-height: 1.5715;
    margin-bottom: 0.5em;
    margin-top: 0;
  }
  button {
    background-color: ${theme.colors.paleBeige};
    border: none;
    color: ${theme.colors.black};
    box-shadow: none;
    font-size: 14px;
    transition: all 0.2s ease-in;

    &:hover,
    &:focus {
      background-color: ${theme.colors.blue};
      color: ${theme.colors.white};
    }
  }
`;

const MobileContainer = styled(Container)<{ $isTransparent?: boolean }>`
  position: fixed;
  height: 60px;
  padding: 14px 20px 14px 20px;
  justify-content: space-between !important;
  align-items: center;
  color: ${theme.colors.black};
  h1 {
    color: ${theme.colors.black};
  }

  ${({ $isTransparent }) =>
    $isTransparent &&
    css`
      background-color: transparent;
      box-shadow: none;
      padding: 14px 20px 14px 20px;
    `}
`;

const MobileMenuItemsContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 360px) {
    width: 80%;
    margin-left: 60px !important;
  }
`;
const HeaderMenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 20px 5px;
`;

const WidthContainer = styled.div`
  color: white;
  display: flex;
  height: 60px;
  width: 100%;
  padding: 0px 20px;
  justify-content: space-between;
  align-items: center;
`;

const NewProfileMenuItem = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { me } = useMe();
  const router = useRouter();
  const { filteredTenants: tenants, hasAccessToTenantByUrl } = useTenants(true);
  const { tenantId } = useRouterMatch();

  const { b2cSignout } = useSignOut();
  const metrics = useMetrics();
  const { t } = useTranslation('Host');

  const ContainerRef = useRef<HTMLDivElement>(null);

  const handleOutSideClick = useCallback(() => {
    setIsOpen(false);
  }, []);

  const [isInviting, setIsInviting] = useState<{
    visible: boolean;
    role: 'student' | 'mentor';
    isSignup?: boolean;
  }>();

  const handleInvite = (
    invite: { visible: boolean; role: 'student' | 'mentor'; isSignup?: boolean },
    from: string,
  ) => {
    metrics.logEvent('Invite.Init', { from });
    handleToggleProfileMenu();
    setIsInviting(invite);
  };

  useOnOutsideClick(ContainerRef, handleOutSideClick);

  const [openNickNameModal, setOpenNickNameModal] = useState(false);

  const handleLogout = () => {
    metrics.logEvent('TopBar.Profile.Signout');
    b2cSignout();
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpen = () => {
    metrics.logEvent('TopBar.Profile.Open');
    setIsOpen(true);
  };

  const handleOpenProfile = () => {
    handleClose();
  };

  const handleNickNameDone = () => {
    setOpenNickNameModal(false);
  };

  const handleToggleProfileMenu = () => {
    if (isOpen) {
      handleClose();
    } else {
      handleOpen();
    }
  };

  const animationProps = {
    initial: { opacity: 0 },
    exit: { opacity: 0 },
    animate: {
      opacity: 1,
    },
    transition: {
      duration: 0.15,
    },
  };

  const activeTenantId = tenants?.user.activeTenantId;

  const isHomePage = router.asPath.split('?')[0] === `/${tenantId}/home`;

  if (!activeTenantId) {
    return (
      <ProfileDefault
        radius={40}
        color={theme.colors.white}
        icon={<CuripodNormal />}
        backgroundColor={theme.colors.white}
      />
    );
  }

  const currentTenantId = isHomePage
    ? getDestinationTenantFromUrl(activeTenantId)
    : getDestinationTenantFromUrl();

  const tenantName = tenants?.user?.availableTenants
    .find(t => t.tenantId === currentTenantId)
    ?.tenantName.substring(0, 30);

  return (
    <NotificationsWrapper style={{ marginRight: 8 }}>
      {isInviting && isInviting.role && (
        <InvitationModal
          visible={!!(isInviting && isInviting.visible)}
          onDone={() => setIsInviting(undefined)}
          role={isInviting?.role}
          isSignup={isInviting?.isSignup}
        />
      )}
      <SmartProfile
        onClick={() => {
          if (hasAccessToTenantByUrl) {
            handleToggleProfileMenu();
          } else {
            router.push(`/${tenantId}/lessons`);
          }
        }}
      />
      {openNickNameModal && (
        <NickNameModal onDone={handleNickNameDone} visible={openNickNameModal} />
      )}
      <AnimatePresence>
        {isOpen && hasAccessToTenantByUrl && (
          <ProfileDropdownContainer ref={ContainerRef}>
            <motion.div {...animationProps}>
              <ProfileMenu>
                <ProfileMenuItem onClick={handleOpenProfile}>
                  <Link href={`/${tenantId}/profile`}>
                    <a>
                      <ProfileInfoItem>
                        <SmartProfile />
                        <h3>{me?.username}</h3>
                        <span>{me?.email}</span>
                        <span style={{ color: '#a5a5a5' }}>{tenantName}</span>
                      </ProfileInfoItem>
                    </a>
                  </Link>
                </ProfileMenuItem>
                <MenuDivider>
                  <Line />
                </MenuDivider>

                <Link href={`/${tenantId}/profile`}>
                  <a>
                    <ProfileMenuItem onClick={handleOpenProfile}>
                      <span>{t('Settings')}</span>
                    </ProfileMenuItem>
                  </a>
                </Link>
                {(me?.role === 'admin' ||
                  me?.role === 'superAdmin' ||
                  me?.role === 'mentor') && (
                  <ProfileMenuItem
                    onClick={() =>
                      handleInvite({ visible: true, role: 'mentor' }, 'Topbar')
                    }
                  >
                    <span>
                      {t('Invite teachers to tenantName', {
                        defaultValue: 'Invite teachers to {{tenantName}}',
                        tenantName: tenantName,
                      })}
                    </span>
                  </ProfileMenuItem>
                )}

                <MenuDivider>
                  <Line />
                </MenuDivider>
                <ProfileMenuItem $disableHover style={{ padding: 20 }}>
                  <Button
                    onClick={handleLogout}
                    height={'32px'}
                    width="100%"
                    type="ghost"
                  >
                    {t('Logout')}
                  </Button>
                </ProfileMenuItem>
              </ProfileMenu>
            </motion.div>
          </ProfileDropdownContainer>
        )}
      </AnimatePresence>
    </NotificationsWrapper>
  );
};

export const BottomBar = () => {
  const { t } = useTranslation('Host');
  const { router, tenantId, parentFolderId, workspaceId } = useRouterMatch();
  const metrics = useMetrics();
  const isNewCreatePageEnabled = useFlag('enableNewCreatePage');

  const { trackEvent } = useIntercom();
  // Make sure the intercom launcher is hidden
  useHideIntercom();
  const createLessonLink = useMemo(() => {
    if (isNewCreatePageEnabled) return `/${tenantId}/create`;
    if (parentFolderId && workspaceId)
      return `/${tenantId}/lessons/new/edit?workspaceId=${workspaceId}&parentFolderId=${parentFolderId}`;
    if (workspaceId) return `/${tenantId}/lessons/new/edit?workspaceId=${workspaceId}`;
    if (parentFolderId)
      return `/${tenantId}/lessons/new/edit?parentFolderId=${parentFolderId}`;
    return `/${tenantId}/lessons/new/edit`;
  }, [parentFolderId, tenantId, workspaceId, isNewCreatePageEnabled]);

  const handleCreateTemplateMobile = () => {
    metrics.logEvent('Lessons.NewTemplate.Start', {
      path: router.pathname,
      placement: 'mobileSidebar',
      workspaceId: workspaceId,
    });
    router.replace(createLessonLink);
    trackEvent('PresentationCreated');
  };

  return (
    <>
      <BottomBarContainer>
        <WidthContainer>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
            <BottomBarButton
              $isActive={router.asPath.split('?')[0] === `/${tenantId}/home`}
            >
              <Link href={`/${tenantId}/home`}>
                <a>
                  <IconWrapper width={'18px'} height={'18px'}>
                    <HomeSvg />
                  </IconWrapper>
                  <span>{t('Home')}</span>
                </a>
              </Link>
            </BottomBarButton>
            <BottomBarButton $isActive={router.asPath === `/${tenantId}/lessons`}>
              <Link href={`/${tenantId}/lessons`}>
                <a>
                  <IconWrapper width={'18px'} height={'18px'}>
                    <FolderSvg />
                  </IconWrapper>
                  <span>{t('Lessons')}</span>
                </a>
              </Link>
            </BottomBarButton>
            <BottomBarButton
              $isActive={false}
              onClick={() => {
                handleCreateTemplateMobile();
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '5px',
                }}
              >
                <IconWrapper width={'18px'} height={'18px'}>
                  <PlusSvg />
                </IconWrapper>
                <span>{t('New')}</span>
              </div>
            </BottomBarButton>

            <BottomBarButton $isActive={router.asPath === `/${tenantId}/reports`}>
              <Link href={`/${tenantId}/reports`}>
                <a>
                  <IconWrapper width={'18px'} height={'18px'}>
                    <ChartSvg />
                  </IconWrapper>
                  <span>{t('Reports')}</span>
                </a>
              </Link>
            </BottomBarButton>
            <BottomBarButton
              styles={{ background: 'none' }}
              $isActive={router.asPath === `/${tenantId}/reports`}
            >
              <Link href={`/${tenantId}/profile`}>
                <a>
                  <NewProfileMenuItem key="profile" />
                </a>
              </Link>
            </BottomBarButton>
          </div>
        </WidthContainer>
      </BottomBarContainer>
    </>
  );
};

const BottomBarButton = styled(Button)<{ $isActive: boolean }>`
  background: ${({ $isActive }) => (!$isActive ? theme.colors.paleBeige : '#f1eae0')};
  color: ${theme.colors.black};
  box-shadow: none;
  padding: 5px;
  width: 50px;
  font-size: 10px;
  transition: 0.2s;

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }

  span {
    height: 12px;
    line-height: 12px;
  }

  svg {
    margin-right: 0;
  }

  &:hover,
  &:focus {
    transform: scale(1.1);
    background-color: #f1eae0;
    color: ${theme.colors.black};
  }
`;

const BottomBarContainer = styled.div`
  z-index: ${zIndexes('TOPBAR')};
  position: fixed;
  padding: 6px 0px;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.paleBeige};
  box-shadow: 0px 4px 4px rgb(0, 0, 0, 0.1);
  height: 60px;
  min-height: 60px;
`;

const IconClickWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 42px;
  height: 42px;
  cursor: pointer;
  width: 42px;
  ${BaseTransition}
  svg {
    height: 23px;
    width: 23px;
  }
  &:hover {
    background-color: rgba(192, 192, 192, 0.3);
  }
`;

const NotificationsWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ClickableIconWrapper = styled.button<{ $hasBackground?: boolean }>`
  height: 100%;
  margin-top: 0px;
  border: none;
  background-color: transparent;
  &:focus,
  &:active {
    outline: none;
  }
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    transform: scale(1.1);
  }
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 28px;
    width: 28px;
  }

  ${({ $hasBackground }) =>
    $hasBackground &&
    css`
      height: unset;
      margin-top: 10px;
      padding: 10px;
      border-radius: 5px;
      background-color: ${theme.colors.paleBeige};
    `}
`;
const ProfileInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 30px;

  h3,
  span {
    text-align: center;
    font-weight: bold;
  }
  h3 {
    color: ${theme.colors.black};
  }
  &&span {
    color: #5f6368;
  }
`;

const MenuDivider = styled.div`
  width: 100%;
  padding: 0px 5px;
`;

const Line = styled.div`
  height: 1px;
  background-color: #a5a5a5;
  width: 100%;
`;

const ProfileMenuItem = styled.li<{ $disableHover?: boolean; $active?: boolean }>`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  padding: 5px 20px;

  ${({ $disableHover }) =>
    !$disableHover &&
    css`
      &:hover {
        background-color: ${theme.colors.greyLight};
      }
    `}
  span {
    color: ${theme.colors.black};
    white-space: nowrap;
    font-weight: bold;
  }
  &:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  ${({ $disableHover }) =>
    $disableHover &&
    css`
      cursor: default;
    `}

  ${({ $active }) =>
    $active &&
    css`
      background-color: ${theme.colors.orange};
      span {
        color: ${theme.colors.white} !important;
      }
    `};
`;

const ProfileMenu = styled.ul`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
  background-color: ${theme.colors.white};
  padding-inline-start: 0px;
  transform-origin: right;
`;

const ProfileDropdownContainer = styled.div`
  position: absolute;
  top: 65px;
  right: 0px;
  transform-origin: right;
  z-index: ${zIndexes('TOPBAR_DROPDOWN')};
`;

export const IconWrapperDown = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    transform-origin: center;
    transform: rotate(90deg);
    height: 16px;
    width: 16px;
  }
`;

const HelpMenuItem = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation('Host');
  const animationProps = {
    initial: { opacity: 0 },
    exit: { opacity: 0 },
    animate: {
      opacity: 1,
    },
    transition: {
      duration: 0.15,
    },
  };

  const { isOpen: intercomIsOpen } = useIsIntercomOpen();
  const { show, hide } = useIntercom();

  const handleHelpClicked = () => {
    if (intercomIsOpen) {
      hide();
    } else {
      show();
    }
  };

  const ContainerRef = useRef<HTMLDivElement>(null);
  const isDesktop = useBreakPoint(700);

  const handleOutSideClick = useCallback(() => {
    setIsOpen(false);
  }, []);

  useOnOutsideClick(ContainerRef, handleOutSideClick);

  const handleWatchHowItWorksCancel = () => {
    setShowHowItWorks(false);
  };

  const [showHowItWorks, setShowHowItWorks] = useState(false);

  return (
    <NotificationsWrapper>
      <SmallIconLinkContainer onClick={() => setIsOpen(!isOpen)}>
        <SmallIcon>
          <QuestionMarkSvg />
        </SmallIcon>
      </SmallIconLinkContainer>
      <AnimatePresence>
        {isOpen && (
          <ProfileDropdownContainer ref={ContainerRef}>
            <motion.div {...animationProps}>
              <ProfileMenu style={{ maxHeight: 200, overflowY: 'auto' }}>
                <ProfileMenuItem $disableHover>
                  <p
                    style={{
                      color: '#5F6368',
                      fontSize: 14,
                      width: 200,
                      margin: '7px 0px',
                    }}
                  >
                    We would love to hear from you 🧡 Please leave your question or
                    feedback in the{' '}
                    <span
                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() => handleHelpClicked()}
                    >
                      Chat
                    </span>
                    .
                  </p>
                </ProfileMenuItem>
                <HowItWorksModal
                  visible={showHowItWorks}
                  onOk={handleWatchHowItWorksCancel}
                  onCancel={handleWatchHowItWorksCancel}
                  centered
                  bodyStyle={{ padding: 0 }}
                  footer={null}
                  closable={false}
                  destroyOnClose
                  width={isDesktop ? '80%' : '90%'}
                >
                  <iframe
                    style={{ borderRadius: 10 }}
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/uaPks8C13Ms"
                    title={t('YouTube video player')}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </HowItWorksModal>
              </ProfileMenu>
            </motion.div>
          </ProfileDropdownContainer>
        )}
      </AnimatePresence>
    </NotificationsWrapper>
  );
};

const SmallIconLinkContainer = styled.div`
  position: relative;
  color: ${theme.colors.black};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SmallIcon = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 26px;
    width: 26px;
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  padding: 8px;
  border-radius: 50px;
  transition: background-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
`;

const HowItWorksModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 10px;
    box-shadow: none;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
    background-color: transparent;
  }

  .ant-modal-body {
    height: 100%;
    width: 100%;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-modal {
    height: 100vh;
    padding: 20px;
  }
`;
const GroupContainer = styled(RootGroupContainer)<{
  $includeMargin?: boolean;
}>`
  ${({ $includeMargin }) =>
    $includeMargin &&
    css`
      & > * {
        margin: 0 10px;
      }
    `}

  & > *:last-child {
    margin: 0px !important;
  }
`;
