import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import { useQuery } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import { ArrowLeftSvg, CheckSvg } from '../assets/icons';
import AIBotSvg from '../assets/icons/AIBotSvg';
import AnonymousParticipantsSvg from '../assets/icons/AnonymousParticipantsSvg';
import ArrowRightSvg from '../assets/icons/ArrowRightSvg';
import InstantFeedbackSvg from '../assets/icons/InstantFeedbackSvg';
import ParticipationSvg from '../assets/icons/ParticipationSvg';
import QuoteSvg from '../assets/icons/QuoteSvg';
import ReportsPagesSvg from '../assets/icons/ReportsPagesSvg';
import StarLandingPageBackground from '../assets/icons/StarLandingPageBackground';
import StarLandingPageBackgroundWhite from '../assets/icons/StarLandingPageBackgroundWhite';
import StarStruckSvg from '../assets/icons/StarStruckSvg';
import ThinkingSvg from '../assets/icons/ThinkingSvg';
import ThumbsUpSvg from '../assets/icons/ThumbsUpSvg';
import WaveLandingPageDiv from '../assets/icons/WaveLandingPageDiv';
import WaveLandingPageDiv2 from '../assets/icons/WaveLandingPageDiv2';
import WaveLandingPageDiv3 from '../assets/icons/WaveLandingPageDiv3';
import WaveStarLandingPageBackground from '../assets/icons/WaveStarLandingPageBackground';
import aileen from '../assets/images/LandingPage/aileen.png';
import claudia from '../assets/images/LandingPage/Claudia.png';
import dalton from '../assets/images/LandingPage/dalton.png';
import garcia from '../assets/images/LandingPage/garcia.png';
import haleyAndEmmy from '../assets/images/LandingPage/haleyandemmy.png';
import LaptopFrame from '../assets/images/LandingPage/laptopframe.png';
import medina from '../assets/images/LandingPage/medina.png';
import mercer from '../assets/images/LandingPage/mercer.png';
import pinecrest from '../assets/images/LandingPage/pinecrest.png';
import pinetree from '../assets/images/LandingPage/pinetree.png';
import psusd from '../assets/images/LandingPage/psusd.png';
import reportBlob from '../assets/images/LandingPage/reportBlob.png';
import roysecity from '../assets/images/LandingPage/roysecity.png';
import stephanie from '../assets/images/LandingPage/stephanie.png';
import DemoGif from '../assets/images/LandingPage/Teacher-StudentGif.gif';
import texasconnections from '../assets/images/LandingPage/texasconnections.png';
import vanguard from '../assets/images/LandingPage/vanguard.png';
import willis from '../assets/images/LandingPage/willis.png';
import theme from '../assets/theme';
import { Button } from '../components/_atoms/Button';
import { IconWrapper } from '../components/_atoms/IconWrapper';
import { HorisontalGeneratorImage } from '../components/_molecules/HorisontalGeneratorCard';
import { useBreakPoint } from '../hooks/useBreakPoint';
import { useMetrics } from '../hooks/useMetrics';
import usePaywallPrompt from '../hooks/usePaywallPrompt';
import { GeneratorMinimumDTO } from '../services/backendService/types';
import { getGeneratorsUrl } from '../services/DiscoverService/discoverUtils';

const Container = styled.div`
  min-height: 100vh;
  background-color: ${theme.colors.landingPageBeige};
`;

const LandingPageSection = styled.div<{
  $backgroundGradient?: string;
  $hasWave: boolean;
}>`
  position: relative;
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.landingPageBeige};
  padding-top: 50px;
  padding-bottom: 50px;
  align-items: center;

  ${({ $backgroundGradient }) =>
    $backgroundGradient &&
    css`
      background: ${$backgroundGradient};
    `}

  ${({ $hasWave }) =>
    $hasWave &&
    css`
      margin-bottom: -2px;
    `}

  @media (max-width: 900px) {
    flex-wrap: wrap-reverse;
    justify-content: center;
  }
`;

const LaptopContainer = styled.div`
  position: relative;
  width: 100%;

  & > img {
    width: 100%;
  }
`;
const SmartboardContainer = styled.div`
  position: relative;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  border: 3px solid ${theme.colors.greyVeryDark};
  background-color: ${theme.colors.blackPure};

  & > img {
    width: 100%;
  }
`;

const DemoContainer = styled.div`
  width: 100%;
  max-width: 1000px;
  margin-top: 50px;
  display: grid;
  grid-template-columns: calc(50% - 10px) calc(50% - 10px);
  grid-column-gap: 20px;
  grid-row-gap: 5px;
  justify-items: center;
  z-index: 1;

  h3 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 0px;
    max-width: 800px;
  }

  @media (max-width: 600px) {
    width: 100%;
    padding: 0 5px;
    h3 {
      font-size: 16px;
    }
  }
`;

const SectionTitle = styled.h2`
  font-size: 42px;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
  max-width: 900px;
  font-weight: 900;
  z-index: 1;

  @media (max-width: 600px) {
    font-size: 24px;
  }
`;

const SectionSubtitle = styled.h3`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
  max-width: 900px;
  z-index: 1;

  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const ResponsiveCTA = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  justify-content: center;
`;

const WaveDivider = styled.div`
  width: 100%;
  height: fit-content;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const StarWaveBackground = styled.div`
  width: 100%;
  height: fit-content;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  svg {
    width: 100%;
  }
`;

const GetStudentsExcited = ({ onSignup }: { onSignup: () => void }) => {
  const metrics = useMetrics();
  return (
    <>
      <LandingPageSection
        $backgroundGradient={'linear-gradient(180deg, #fff7ef 18.2%, #ffa154 93.41%)'}
        $hasWave={true}
      >
        <StarWaveBackground>
          <WaveStarLandingPageBackground />
        </StarWaveBackground>
        <SectionTitle>Feel the Buzz of 100% Participation</SectionTitle>
        <SectionSubtitle>Lessons and Activities Powered by AI Feedback</SectionSubtitle>
        <ResponsiveCTA>
          <div
            style={{
              display: 'grid',
              justifyContent: 'center',
              gap: 10,
            }}
          >
            <span
              style={{
                width: '100%',
                textAlign: 'center',
                color: theme.colors.black,
                fontWeight: 'bold',
              }}
            >
              Teachers
            </span>
            <OrangePrimaryButton styles={{ width: '210px' }} onClick={() => onSignup()}>
              Sign Up For Free
            </OrangePrimaryButton>
          </div>
          <div
            style={{
              display: 'grid',
              justifyContent: 'center',
              gap: 10,
            }}
          >
            <span
              style={{
                width: '100%',
                textAlign: 'center',
                color: theme.colors.black,
                fontWeight: 'bold',
              }}
            >
              Administrators
            </span>
            <a href="/quotes/" target="_curipod_quote">
              <WhiteSecondaryButton
                type="ghost"
                styles={{ width: '210px' }}
                onClick={() => {
                  metrics.logEvent('FrontPage.QuoteClicked', {
                    from: 'top',
                  });
                }}
              >
                Get a Quote
              </WhiteSecondaryButton>
            </a>
          </div>
        </ResponsiveCTA>
        <DemoContainer>
          <h3>Student Screen</h3>
          <h3>Classroom screen</h3>
          <LaptopContainer>
            <img src={LaptopFrame.src} alt="laptop frame" />
            <StudentExperienceContainer>
              <img src={DemoGif.src} alt="drawing" />
            </StudentExperienceContainer>
          </LaptopContainer>
          <SmartboardContainer>
            <TeacherExperienceContainer>
              <img src={DemoGif.src} alt="drawing" />
            </TeacherExperienceContainer>
          </SmartboardContainer>
        </DemoContainer>
      </LandingPageSection>
      <WaveDivider>
        <WaveLandingPageDiv />
      </WaveDivider>
    </>
  );
};

const OrangePrimaryButton = styled(Button)`
  background-color: ${theme.colors.orange};
  width: 100%;
  height: 42px;
  border-radius: 5px;
  box-shadow: ${theme.effects.magicFloatingShadow};

  span {
    font-size: 20px;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: ${theme.colors.orangeDark};
    box-shadow: none;
  }
`;

const WhiteSecondaryButton = styled(Button)`
  background-color: ${theme.colors.leastTranslucentWhite};
  color: ${theme.colors.black};
  width: 100%;
  height: 42px;
  border-radius: 5px;
  box-shadow: ${theme.effects.magicFloatingShadow};
  border: 1px solid ${theme.colors.black};

  span {
    font-size: 20px;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: ${theme.colors.greyLight}99;
    color: ${theme.colors.black};
    border: 1px solid ${theme.colors.black};
    box-shadow: none;
  }
`;

const StudentExperienceContainer = styled.div`
  position: absolute;
  top: 10px;
  left: 13%;
  place-items: center;
  margin: -5px;
  overflow: hidden;
  height: 85%;
  width: 78%;
  object-fit: ;

  img {
    height: 110%;
    position: absolute;
    top: 50%;
    left: -5px;
    transform: translateY(-50%);
  }
`;
const TeacherExperienceContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translate(-50%, 0%);
  place-items: center;
  overflow: hidden;
  height: 100%;
  width: 90%;

  img {
    height: 110%;
    position: absolute;
    top: 50%;
    right: -5px;
    transform: translateY(-50%);
  }
`;

const ImageContainer = styled.div`
  max-width: 200px;
  max-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media (max-width: 600px) {
    max-width: 150px;
    max-height: 70px;
  }
`;

const ThousandsOfClassrooms = () => {
  return (
    <LandingPageSection $backgroundGradient={undefined} $hasWave={false}>
      <SectionTitle>Trusted by School and District Partners</SectionTitle>
      <LogosGrid>
        <TopRow>
          <ImageContainer>
            <img src={pinecrest.src} alt="pinecrest school logo" />
          </ImageContainer>
          <ImageContainer>
            <img src={vanguard.src} alt="vanguard school logo" />
          </ImageContainer>
          <ImageContainer>
            <img src={willis.src} alt="willis school logo" />
          </ImageContainer>
          <ImageContainer>
            <img src={dalton.src} alt="dalton school logo" />
          </ImageContainer>
          <ImageContainer>
            <img src={psusd.src} alt="psusd school logo" />
          </ImageContainer>
          <ImageContainer>
            <img src={texasconnections.src} alt="texasconnections school logo" />
          </ImageContainer>
          <ImageContainer>
            <img src={medina.src} alt="medina school logo" />
          </ImageContainer>
          <ImageContainer>
            <img src={mercer.src} alt="mercer school logo" />
          </ImageContainer>
          <ImageContainer>
            <img src={roysecity.src} alt="roysecity school logo" />
          </ImageContainer>
        </TopRow>
      </LogosGrid>
    </LandingPageSection>
  );
};

const LogosGrid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin-bottom: 50px;
  align-items: center;
  gap: 20px;
  justify-items: center;
  padding-top: 20px;
  max-width: 900px;
`;

const TopRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  img {
    width: 100%;
  }
`;

const SeeLessons = ({ onSignup }: { onSignup: () => void }) => {
  const isLargerThan800 = useBreakPoint(800);
  const router = useRouter();
  const { data: collections } = useQuery(
    ['front-page-collections'],
    async () => {
      // This is prefetched on the server, so I'm just mocking the promise resolve for typescript.
      // stale time will prevent this query to be fetched.
      return Promise.resolve<
        {
          title: string;
          collectionId: string;
          generators: GeneratorMinimumDTO[];
        }[]
      >([]);
    },
    {
      staleTime: Infinity,
    },
  );

  const collection = collections?.[0];
  return (
    <LandingPageSection
      $backgroundGradient={undefined}
      $hasWave={false}
      style={{ gap: 30 }}
    >
      <StarWaveBackground>
        <StarLandingPageBackground />
      </StarWaveBackground>
      <SectionTitle>
        See What Lessons Made Over 8 Million Students Love Curipod
      </SectionTitle>
      {collection && (
        <div
          style={{
            width: '100%',
            marginBottom: '40px',
          }}
        >
          {isLargerThan800 && <LessonSlidingView generators={collection.generators} />}
          {!isLargerThan800 && (
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 10,
                justifyContent: 'center',
              }}
            >
              {collection.generators.map(generator => (
                <div key={generator.id} style={{ width: 150 }}>
                  <CustomizabeLessonCard
                    isStandardsAligned={!!generator.learningStandards?.length}
                    coverImageId={generator.coverImageId}
                    isPremiumGenerator={
                      generator.generatorPlan === 'enterprise' ||
                      generator.generatorPlan === 'premium'
                    }
                    onClick={() => {
                      router.push(
                        getGeneratorsUrl({
                          title: generator.title,
                          generatorId: generator.id,
                        }),
                      );
                    }}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      <OrangePrimaryButton styles={{ width: '210px' }} onClick={() => onSignup()}>
        Sign Up For Free
      </OrangePrimaryButton>
    </LandingPageSection>
  );
};

const LessonSlidingView = ({ generators }: { generators: GeneratorMinimumDTO[] }) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null); // Track the hovered card
  const [startIndex, setStartIndex] = useState(0);
  const router = useRouter();
  // Calculate the displayed items based on the startIndex, looping around
  const displayedGenerators = useMemo(() => {
    const endIndex = startIndex + 5;
    if (endIndex <= generators.length) {
      return generators.slice(startIndex, endIndex);
    }
    return [
      ...generators.slice(startIndex),
      ...generators.slice(0, endIndex % generators.length),
    ];
  }, [generators, startIndex]);

  const handleRightArrowClicked = () => {
    setStartIndex(prevIndex => (prevIndex + 1) % generators.length);
  };
  const handleLeftArrowClicked = () => {
    setStartIndex(prevIndex => (prevIndex - 1 + generators.length) % generators.length);
  };

  const getScale = (visualIndex: number) => {
    const distanceFromCenter = Math.abs(2 - visualIndex);
    return 1 - distanceFromCenter * 0.1;
  };

  const cardVariants = (visualIndex: number) => {
    return {
      initial: {
        scale: getScale(visualIndex),
        opacity: 1,
        zIndex: 5,
        transition: { duration: 0.2 },
        boxShadow: theme.effects.magicFloatingShadow,
        transformOrigin:
          visualIndex === 0 ? 'right' : visualIndex === 4 ? 'left' : 'center',
        y: 0,
      },
      hover: {
        scale: hoveredIndex === visualIndex ? 1.05 : getScale(visualIndex),
        zIndex: hoveredIndex === visualIndex ? 10 : 5,
        boxShadow: theme.effects.magicFloatingShadowHovered,
        transition: { duration: 0.2, ease: 'linear', bounce: 0, stiffness: 300 },
        y: hoveredIndex === visualIndex ? -10 : 0,
      },
    };
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'grid',
          gridTemplateColumns: '35px auto 35px',
          alignItems: 'center',
        }}
      >
        <StyledArrowButton
          styles={{
            background: theme.colors.white,
            border: 'none',
            height: 35,
            width: 35,
            borderRadius: '50%',
            color: theme.colors.black,
          }}
          type="ghost"
          icon={<ArrowLeftSvg />}
          notext={true}
          onClick={() => {
            handleLeftArrowClicked();
          }}
        />
        <CardsContainer>
          {displayedGenerators.map((generator, i) => (
            <Card
              key={`${startIndex}-${i}`}
              variants={cardVariants(i)}
              custom={i}
              initial="initial"
              animate="animate"
              whileHover="hover" // Activate hover state
              onMouseEnter={() => setHoveredIndex(i)} // Set the hovered card
              onMouseLeave={() => setHoveredIndex(null)} // Reset on hover end
            >
              <CreateWithAIFloatingCard
                key={generator.id}
                coverImageId={generator.coverImageId}
                isStandardsAligned={!!generator.learningStandards?.length}
                isPremiumGenerator={
                  generator.generatorPlan === 'enterprise' ||
                  generator.generatorPlan === 'premium'
                }
                onClick={() => {
                  router.push(
                    getGeneratorsUrl({
                      title: generator.title,
                      generatorId: generator.id,
                    }),
                  );
                }}
              ></CreateWithAIFloatingCard>
            </Card>
          ))}
        </CardsContainer>

        <StyledArrowButton
          styles={{
            background: theme.colors.white,
            border: 'none',
            height: 35,
            width: 35,
            borderRadius: '50%',
            color: theme.colors.black,
          }}
          type="ghost"
          icon={<ArrowRightSvg />}
          notext={true}
          onClick={() => {
            handleRightArrowClicked();
          }}
        />
      </div>
      {/* //TODO should we show the title of the centered card?
      <p style={{ marginTop: 10, width: '100%', textAlign: 'center' }}>
        {displayedGenerators[2].title}
      </p> */}
    </>
  );
};

const CreateWithAIFloatingCard = ({
  coverImageId,
  onClick,
  isPremiumGenerator,
  isStandardsAligned,
}: {
  coverImageId: string | null | undefined;
  isPremiumGenerator: boolean;
  onClick: () => void;
  isStandardsAligned: boolean;
}) => {
  const { tenantPlan } = usePaywallPrompt({
    startState: { screen: 'schoolPlanPaywall' },
  });

  return (
    <FloatingCardContainer $isDesktopCard={true} onClick={onClick}>
      <HorisontalGeneratorImage
        coverImageId={coverImageId}
        isPremiumGenerator={isPremiumGenerator}
        tenantPlan={tenantPlan}
        isStandardsAligned={isStandardsAligned}
      />
    </FloatingCardContainer>
  );
};
const CustomizabeLessonCard = ({
  coverImageId,
  onClick,
  isPremiumGenerator,
  isStandardsAligned,
}: {
  coverImageId: string | null | undefined;
  isPremiumGenerator: boolean;
  onClick: () => void;
  isStandardsAligned: boolean;
}) => {
  const { tenantPlan } = usePaywallPrompt({
    startState: { screen: 'schoolPlanPaywall' },
  });

  return (
    <FloatingCardContainer
      $isDesktopCard={false}
      style={{ padding: 0 }}
      onClick={onClick}
    >
      <HorisontalGeneratorImage
        coverImageId={coverImageId}
        isPremiumGenerator={isPremiumGenerator}
        tenantPlan={tenantPlan}
        isStandardsAligned={isStandardsAligned}
      />
    </FloatingCardContainer>
  );
};

const StyledArrowButton = styled(Button)`
  padding: 0px;
  svg {
    height: 28px;
    width: 28px;
  }
`;

const FloatingCardContainer = styled.div<{
  $borderColor?: string;
  $isDesktopCard: boolean;
}>`
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: ${theme.effects.magicFloatingShadow};
  background-color: ${theme.colors.paleBeige};
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;

  @media (max-width: 700px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    position: relative;
    overflow: hidden;
    padding-left: 10px;
    border-radius: 5px;

    ${({ $isDesktopCard, $borderColor }) =>
      $isDesktopCard &&
      css`
        &::after {
          position: absolute;
          top: 0;
          left: 0;
          content: '';
          width: 5px;
          height: 100%;
          background-color: ${$borderColor ? $borderColor : theme.colors.paleBeige}};
        }
      `}

    button {
      padding: 0;
    }
  }
`;

const CardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 100%;
`;

const Card = styled(motion.div)`
  height: auto;
  background-color: white;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  width: 100%;
  max-width: 20%;
  flex-shrink: 0;
`;

const ExcitingLiteracy = () => {
  const metrics = useMetrics();
  return (
    <>
      <LandingPageSection
        $backgroundGradient={'linear-gradient(180deg, #fff7ef 18.2%, #ffa154 93.41%)'}
        $hasWave={true}
      >
        <SectionTitle>Exciting Lessons, Real-Time AI-Feedback</SectionTitle>
        <LiteracyFeaturesGrid>
          <LiteracyFeature>
            <IconWrapper cursor={'default'} height="50px" width="50px">
              <StarStruckSvg />
            </IconWrapper>
            <div>
              <h3>Learning that wows and delights</h3>
              <p>
                Customize high-quality lessons to topics students care about with the
                power of AI
              </p>
            </div>
          </LiteracyFeature>
          <LiteracyFeature>
            <IconWrapper cursor={'default'} height="50px" width="50px">
              <ParticipationSvg />
            </IconWrapper>
            <div>
              <h3>100% participation, lively discussion</h3>
              <p>
                Tech that motivates supportive student interactions (not alone behind
                screens)
              </p>
            </div>
          </LiteracyFeature>
          <LiteracyFeature>
            <IconWrapper cursor={'default'} height="50px" width="50px">
              <AIBotSvg />
            </IconWrapper>
            <div>
              <h3>AI feedback drives growth</h3>
              <p>
                Real-time, rubric-aligned, personalized, and actionable feedback
                accelerates progress
              </p>
            </div>
          </LiteracyFeature>
          <LiteracyFeature>
            <IconWrapper cursor={'default'} height="50px" width="50px">
              <ReportsPagesSvg />
            </IconWrapper>
            <div>
              <h3>AI-powered reports save time</h3>
              <p>
                Top understandings and misconceptions, plus engaging ideas to close gaps
              </p>
            </div>
          </LiteracyFeature>
        </LiteracyFeaturesGrid>

        <a href="/quotes/" target="_curipod_quote">
          <WhiteSecondaryButton
            type="ghost"
            styles={{ width: '210px' }}
            onClick={() => {
              metrics.logEvent('FrontPage.QuoteClicked', {
                from: 'top',
              });
            }}
          >
            Get a Quote
          </WhiteSecondaryButton>
        </a>
      </LandingPageSection>
      <WaveDivider>
        <WaveLandingPageDiv2 />
      </WaveDivider>
    </>
  );
};

const LiteracyFeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  justify-items: center;
  align-items: center;
  max-width: 1000px;
  padding-bottom: 40px;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    justify-items: start;
  }
`;

const LiteracyFeature = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr;
  gap: 10px;
  align-items: center;
  justify-items: center;

  h3 {
    font-size: 24px;
    font-weight: 900;
    margin-bottom: 0px;
  }

  p {
    font-size: 20px;
    font-weight: 700;
  }

  @media (max-width: 600px) {
    h3 {
      font-size: 20px;
    }
    p {
      font-size: 16px;
    }
  }
`;

const AllStudentsOnTask = ({ onSignup }: { onSignup: () => void }) => {
  return (
    <LandingPageSection
      $backgroundGradient={undefined}
      $hasWave={true}
      style={{ gap: 30 }}
    >
      <StarWaveBackground>
        <StarLandingPageBackground />
      </StarWaveBackground>
      <SectionTitle>
        Experience the Buzz of 100% of Students Writing on Task at Once
      </SectionTitle>
      <StudentsOnTaskGrid>
        <FancyIconDiv $color={theme.colors.purple}>
          <IconWrapper
            cursor={'default'}
            color={theme.colors.purple}
            height="50px"
            width="60px"
          >
            <AnonymousParticipantsSvg />
          </IconWrapper>
          <p>Student names are hidden - only the teacher can see who&apos;s who</p>
        </FancyIconDiv>
        <FancyIconDiv $color={theme.colors.orange}>
          <IconWrapper
            cursor={'default'}
            color={theme.colors.orange}
            height="50px"
            width="auto"
          >
            <ThinkingSvg />
          </IconWrapper>
          <p>Space to think for yourself, and see your work on the screen</p>
        </FancyIconDiv>
        <FancyIconDiv $color={theme.colors.green}>
          <IconWrapper
            cursor={'default'}
            color={theme.colors.green}
            height="50px"
            width="auto"
          >
            <ThumbsUpSvg />
          </IconWrapper>
          <p>Learning from each other through supportive peer voting</p>
        </FancyIconDiv>
        <FancyIconDiv $color={theme.colors.blue}>
          <IconWrapper
            cursor={'default'}
            color={theme.colors.blue}
            height="50px"
            width="50px"
          >
            <InstantFeedbackSvg />
          </IconWrapper>
          <p>Rubric-based AI feedback puts you in charge of your growth</p>
        </FancyIconDiv>
      </StudentsOnTaskGrid>
      <OrangePrimaryButton styles={{ width: '210px' }} onClick={() => onSignup()}>
        Sign Up For Free
      </OrangePrimaryButton>
    </LandingPageSection>
  );
};

const StudentsOnTaskGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  justify-items: center;
  align-items: center;
  max-width: 1000px;
  padding-bottom: 40px;
  z-index: 1;

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    justify-items: start;
  }
`;

const FancyIconDiv = styled.div<{ $color: string }>`
  background-color: ${theme.colors.white};
  color: ${theme.colors.black};
  font-size: 20px;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 10px;
  width: 300px;
  border-bottom: 5px solid ${({ $color }) => $color};

  svg {
    height: 50px;
    padding: 2px;
  }
`;

const TestPrep = () => {
  const isLargerThan800 = useBreakPoint(800);
  const metrics = useMetrics();
  return (
    <LandingPageSection
      $backgroundGradient={'linear-gradient(180deg, #fff7ef 18.2%, #ffa154 93.41%)'}
      $hasWave={false}
      style={{ paddingBottom: 50 }}
    >
      <StarWaveBackground>
        <WaveStarLandingPageBackground />
      </StarWaveBackground>
      <div
        style={{
          display: 'flex',
          gap: 20,
          zIndex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {isLargerThan800 && (
          <div style={{ width: '30%', maxWidth: 350, objectFit: 'contain' }}>
            <img style={{ width: '100%' }} src={reportBlob.src} alt="test prep report" />
          </div>
        )}
        <div>
          <SectionTitle style={{ textAlign: 'left' }}>
            Test Prep with Proven Double Digit ELA Results
          </SectionTitle>
          <SectionSubtitle style={{ textAlign: 'left' }}>
            Learn how Curipod gets students jumping out of their seats for test prep and
            leads to unprecedented gains.
          </SectionSubtitle>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 20,
              justifyContent: 'center',
            }}
          >
            <a
              href={`https://curipod.com/Curipod_Case_Study_South_Texas.pdf`}
              target="__blank"
            >
              <WhiteSecondaryButton
                styles={{ width: '230px' }}
                onClick={() => {
                  metrics.logEvent('FrontPage.ReadCaseStudy', {
                    from: 'top',
                  });
                }}
              >
                Read the Case Study
              </WhiteSecondaryButton>
            </a>

            {/* <WhiteSecondaryButton styles={{ width: '210px' }}>
              Explore Test Prep
            </WhiteSecondaryButton> */}
          </div>
        </div>
      </div>
    </LandingPageSection>
  );
};

const QuoteText = styled.p<{ $fontSize: number }>`
  font-size: ${({ $fontSize }) => $fontSize}px;
  font-weight: 700;
  margin-bottom: 0px;
  max-width: 900px;
  font-weight: 700;

  @media (max-width: 700px) {
    font-size: ${({ $fontSize }) => $fontSize - 6}px;
  }
`;

const teacherQuotes: {
  quote: React.ReactNode;
  image: React.ReactNode;
  name: React.ReactNode;
}[] = [
  {
    quote: (
      <QuoteText $fontSize={32}>
        I get my <strong>weekends back!</strong>
      </QuoteText>
    ),
    image: <img src={pinetree.src} alt="Pine tree logo" />,
    name: <p>Pine Tree ELA teacher</p>,
  },
  {
    quote: (
      <QuoteText $fontSize={32}>
        You made me <strong>enjoy writing and reading</strong>!
      </QuoteText>
    ),
    image: <img src={garcia.src} alt="Garcia logo" />,
    name: <p>Middle School Student, Garcia Middle School</p>,
  },

  {
    quote: (
      <QuoteText $fontSize={22}>
        As an instructional technologist I can easily create lessons for my PDs and help
        teachers getting started. We get more creative with AI generated activities and
        the <strong>student engagement is substantially improved</strong>
      </QuoteText>
    ),
    image: <img src={stephanie.src} alt="Stephanie Howell" />,
    name: <p>Stephanie Howell, Pickerington Local School District</p>,
  },
  {
    quote: (
      <QuoteText $fontSize={22}>
        I am a teacher of 15 years who, until recently, has not delved deeply into tech in
        the classroom, and here I am encouraging colleagues to come on in and see Curipod!
        The <strong>time saved</strong>, and the{' '}
        <strong>improvement in my lesson materials</strong> , is tangible.
      </QuoteText>
    ),
    image: <img src={aileen.src} alt="Aileen Wallace" />,
    name: <p>Aileen Wallace, Teacher of Modern Studies, Graeme High School, Scotland</p>,
  },
  {
    quote: (
      <QuoteText $fontSize={32}>
        Another 8th grade class of kiddos using Curipod for the very first time ...
        <strong>100% participation</strong>!
      </QuoteText>
    ),
    image: <img src={claudia.src} alt="Claudia Garcia" />,
    name: <p>Claudia Garcia, middle school teacher, Garcia Middle School</p>,
  },
  // { //TODO Get permission to use this
  //   quote: (
  //     <QuoteText $fontSize={22}>
  //       If you&apos;re a classroom teacher and you aren&apos;t using Curipod{' '}
  //       <strong>you are missing out</strong>. It&apos;s basically Nearpod meets Quiplash
  //       and my students LOVE IT! It incorporates AI to make even mundane topics (like
  //       classroom supplies locations) engaging!
  //     </QuoteText>
  //   ),
  //   image: <img src={haleyAndEmmy.src} alt="Haley and Emmy" />,
  //   name: (
  //     <p>
  //       Haley & Emmy on{' '}
  //       <Link
  //         href="https://www.tiktok.com/@handemresources/video/7416433910306868523?_t=8pstXqLpaq1&_r=1"
  //         passHref
  //         prefetch={false}
  //       >
  //         <a target="__blank" rel="noopener noreferrer">
  //           TikTok
  //         </a>
  //       </Link>
  //     </p>
  //   ),
  // },
];

const QuotesSection = ({ onSignup }: { onSignup: () => void }) => {
  const [visibleQuoteIndex, setVisibleQuoteIndex] = useState(0);

  return (
    <>
      <LandingPageSection
        style={{ backgroundColor: theme.colors.landingPageOrange }}
        $backgroundGradient={undefined}
        $hasWave={true}
      >
        <StarWaveBackground>
          <StarLandingPageBackgroundWhite />
        </StarWaveBackground>
        <SectionTitle style={{ color: theme.colors.white }}>
          What Are Teachers Saying?
        </SectionTitle>
        {teacherQuotes.map((quote, i) => (
          <QuoteCard
            isVisible={i === visibleQuoteIndex}
            key={i}
            quote={quote.quote}
            image={quote.image}
            name={quote.name}
          />
        ))}

        <div
          style={{
            display: 'flex',
            gap: 10,
            justifyContent: 'center',
            marginTop: 20,
            marginBottom: 50,
          }}
        >
          {teacherQuotes.map((_, i) => (
            <DotButton
              onClick={() => setVisibleQuoteIndex(i)}
              key={i}
              $isActive={i === visibleQuoteIndex}
            />
          ))}
        </div>

        <WhiteSecondaryButton onClick={() => onSignup()} styles={{ width: '210px' }}>
          Sign Up For Free
        </WhiteSecondaryButton>
      </LandingPageSection>
      <WaveDivider>
        <WaveLandingPageDiv />
      </WaveDivider>
    </>
  );
};

const QuoteCard = ({
  quote,
  image,
  name,
  isVisible,
}: {
  quote: React.ReactNode;
  image: React.ReactNode;
  name: React.ReactNode;
  isVisible: boolean;
}) => {
  return (
    <QuoteCardContainer $isVisible={isVisible}>
      <IconWrapper
        cursor={'default'}
        style={{ position: 'absolute', top: -25, left: 20 }}
        height="50px"
        width="50px"
      >
        <QuoteSvg />
      </IconWrapper>
      <div style={{ display: 'flex', height: '100%', alignItems: 'center' }}>{quote}</div>
      <QuoterContainer>
        <QuoteImage>{image}</QuoteImage>
        <QuoteName>{name}</QuoteName>
      </QuoterContainer>
    </QuoteCardContainer>
  );
};

const QuoteCardContainer = styled.div<{ $isVisible: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  align-items: start;
  max-width: 1000px;
  height: 350px;
  width: 100%;
  padding-bottom: 40px;
  z-index: 1;
  background: ${theme.colors.white};
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 30px;
  box-shadow: ${theme.effects.floatingCreatorElementShadow};

  @media (max-width: 500px) {
    height: 400px;
  }

  ${({ $isVisible }) =>
    !$isVisible &&
    css`
      display: none;
    `}
`;

const QuoteImage = styled.div`
  object-fit: cover;
  width: 70px;
  height: 70px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  @media (max-width: 700px) {
    width: 50px;
    height: 50px;
  }
`;

const QuoterContainer = styled.div`
  display: grid;
  grid-template-columns: 70px 1fr;
  align-items: center;
  justify-content: center;

  @media (max-width: 700px) {
    grid-template-columns: 50px 1fr;
  }
`;

const QuoteName = styled.div`
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 0px;
  max-width: 300px;
  margin-left: 20px;
  z-index: 1;

  p {
    margin-bottom: 0px;
  }

  a {
    text-decoration: underline;
    color: ${theme.colors.greyDark};
  }

  a:hover {
    text-decoration: none;
  }

  @media (max-width: 700px) {
    font-size: 16px;
  }
`;

const DotButton = styled(Button)<{ $isActive: boolean }>`
  visibility: visible !important;
  margin: 0 5px;
  width: 25px !important;
  height: 25px;
  border-radius: 50%;
  padding: 0px;
  background-color: #ececec;

  &:hover,
  &:focus,
  &:active {
    background-color: ${theme.colors.white};
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      background: ${theme.colors.white};
      box-shadow: 1.62951px 1.62951px 16.2951px rgba(0, 0, 0, 0.2);
    `}
`;

const ChecklistSection = () => {
  return (
    <>
      <LandingPageSection
        $backgroundGradient={'linear-gradient(180deg, #FFF7EF 18.2%, #FFC99C 58.44%)'}
        $hasWave={true}
      >
        <ul style={{ padding: '0px', margin: '20px auto' }}>
          <ChecklistItem>
            <IconWrapper
              cursor={'default'}
              height="40px"
              width="40px"
              color={theme.colors.green}
            >
              <CheckSvg />
            </IconWrapper>
            <p>Aligned to standards and state assessments</p>
          </ChecklistItem>
          <ChecklistItem>
            <IconWrapper
              cursor={'default'}
              height="40px"
              width="40px"
              color={theme.colors.green}
            >
              <CheckSvg />
            </IconWrapper>
            <p>Safe and secure (FERPA & COPPA Compliant)</p>
          </ChecklistItem>
          <ChecklistItem>
            <IconWrapper
              cursor={'default'}
              height="40px"
              width="40px"
              color={theme.colors.green}
            >
              <CheckSvg />
            </IconWrapper>
            <p>Teacher-driven lessons - no student accounts needed</p>
          </ChecklistItem>
          <ChecklistItem>
            <IconWrapper
              cursor={'default'}
              height="40px"
              width="40px"
              color={theme.colors.green}
            >
              <CheckSvg />
            </IconWrapper>
            <p>Intentional integration of AI</p>
          </ChecklistItem>
          <ChecklistItem>
            <IconWrapper
              cursor={'default'}
              height="40px"
              width="40px"
              color={theme.colors.green}
            >
              <CheckSvg />
            </IconWrapper>
            <p>
              Lessons designed by educators,{' '}
              <Link href="/learning-principles" passHref prefetch={false}>
                <a target="__blank" rel="noopener noreferrer">
                  learn more about our learning principles here.
                </a>
              </Link>
            </p>
          </ChecklistItem>
        </ul>
      </LandingPageSection>
      <WaveDivider>
        <WaveLandingPageDiv3 />
      </WaveDivider>
    </>
  );
};

const ChecklistItem = styled.li`
  display: flex;
  align-items: start;
  gap: 10px;
  font-weight: 700;
  margin-bottom: 0px;
  max-width: 900px;

  p {
    font-size: 30px;
  }

  a {
    text-decoration: underline;
  }

  a:hover {
    text-decoration: none;
  }

  @media (max-width: 700px) {
    p {
      font-size: 22px;
    }
  }
`;

const InspireCuriositySection = () => {
  const metrics = useMetrics();
  return (
    <LandingPageSection
      style={{ minHeight: 300, zIndex: 0 }}
      $backgroundGradient={undefined}
      $hasWave={true}
    >
      <StarWaveBackground style={{ height: 300, overflow: 'hidden' }}>
        <StarLandingPageBackground />
      </StarWaveBackground>
      <SectionTitle style={{ marginBottom: 40 }}>
        Inspire Curious Students, Together
      </SectionTitle>

      <a href="/quotes/" target="_curipod_quote">
        <OrangePrimaryButton
          styles={{ width: '210px' }}
          onClick={() => {
            metrics.logEvent('FrontPage.QuoteClicked', {
              from: 'top',
            });
          }}
        >
          Get a Quote
        </OrangePrimaryButton>
      </a>
    </LandingPageSection>
  );
};

const LandingPageHome = () => {
  const router = useRouter();
  const metrics = useMetrics();
  const handleSignupClicked = () => {
    metrics.logEvent('FrontPage.SignupClicked', {
      from: 'tryForFree',
    });
    router.push({
      pathname: '/signup',
      query: { callback: window.location.href },
    });
  };

  return (
    <Container>
      <GetStudentsExcited onSignup={handleSignupClicked} />
      <SeeLessons onSignup={handleSignupClicked} />
      <ExcitingLiteracy />
      <AllStudentsOnTask onSignup={handleSignupClicked} />
      <ThousandsOfClassrooms />
      <TestPrep />
      <QuotesSection onSignup={handleSignupClicked} />
      <ChecklistSection />
      <InspireCuriositySection />
    </Container>
  );
};

export default LandingPageHome;
