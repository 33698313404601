import GreenCheckSvg from '../../../assets/icons/GreenCheckSvg';
import RedStopSvg from '../../../assets/icons/RedStopSvg';

export const featureCompareTableData: {
  heading: string;
  rows: {
    feature: React.ReactNode;
    basic: React.ReactNode;
    schoolPlan: React.ReactNode;
  }[];
}[] = [
  {
    heading: 'Content',
    rows: [
      {
        feature: <strong>Unlimited storage of lessons and student participants</strong>,
        basic: <GreenCheckSvg />,
        schoolPlan: <GreenCheckSvg />,
      },
      {
        feature: (
          <strong>Lessons for all subjects including niche elective material</strong>
        ),
        basic: 'Limited',
        schoolPlan: 'Unlimited',
      },
      {
        feature: (
          <>
            <strong>Test prep. </strong>
            Access to customize thousands of standards aligned state test prep lessons for
            all 50 states &#40;3rd-12th grade&#41; + ACT
          </>
        ),
        basic: <RedStopSvg />,
        schoolPlan: <GreenCheckSvg />,
      },
      {
        feature: (
          <strong>
            Curipod Immersive standards aligned &#40;CCSS & TEKS&#41; reading and writing
            ELA package for middle school
          </strong>
        ),
        basic: <RedStopSvg />,
        schoolPlan: <GreenCheckSvg />,
      },
      {
        feature: <strong>Bilingual &#40;ESL/ELL&#41; Lessons</strong>,
        basic: <RedStopSvg />,
        schoolPlan: <GreenCheckSvg />,
      },
    ],
  },
  {
    heading: 'Customization',
    rows: [
      {
        feature: <strong>Customize Lessons using your own Rubric</strong>,
        basic: <RedStopSvg />,
        schoolPlan: <GreenCheckSvg />,
      },
      {
        feature: <strong>Student Written Responses</strong>,
        basic: 'up to 1,000 characters',
        schoolPlan: 'up to 2,300 characters',
      },
      {
        feature: <strong>Content Translations</strong>,
        basic: 'Unlimited',
        schoolPlan: 'Unlimited',
      },
      {
        feature: (
          <>
            <strong>Download Lessons as PDF </strong>&#40;for absent students, offline
            access, etc.&#41;
          </>
        ),
        basic: <GreenCheckSvg />,
        schoolPlan: <GreenCheckSvg />,
      },
      {
        feature: (
          <>
            <strong>Student Insights </strong>&#40;aligned to your Standard and Learning
            Objective&#41;
          </>
        ),
        basic: <RedStopSvg />,
        schoolPlan: <GreenCheckSvg />,
      },
      {
        feature: <strong>Shared lesson folders across schools & district</strong>,
        basic: <RedStopSvg />,
        schoolPlan: <GreenCheckSvg />,
      },
    ],
  },
  {
    heading: 'Implementation & Support',
    rows: [
      {
        feature: <strong>Customer Support</strong>,
        basic: 'Basic support',
        schoolPlan: 'Priority support',
      },
      {
        feature: (
          <>
            <strong>SSO</strong> &#40;Classlink, Clever, Google, etc&#41;
          </>
        ),
        basic: <RedStopSvg />,
        schoolPlan: <GreenCheckSvg />,
      },
      {
        feature: (
          <>
            <strong>Implementation Support</strong> &#40;courses, etc&#41;
          </>
        ),
        basic: <RedStopSvg />,
        schoolPlan: <GreenCheckSvg />,
      },
    ],
  },
  {
    heading: 'Data Privacy & AI Safety',
    rows: [
      {
        feature: <strong>Compliant with:</strong>,
        basic:
          'All federal and state privacy laws, including FERPA, COPPA, SOPPA, GDPR Compliant',
        schoolPlan:
          'All federal and state privacy laws, including FERPA, COPPA, SOPPA, GDPR Compliant',
      },
      {
        feature: <strong>Training of AI Models</strong>,
        basic: 'No student or teacher data used',
        schoolPlan: 'No student or teacher data used',
      },
      {
        feature: (
          <strong>
            Teacher can control and review all AI output before it&apos;s received by
            students
          </strong>
        ),
        basic: <GreenCheckSvg />,
        schoolPlan: (
          <>
            <GreenCheckSvg /> <span>Real time moderation</span>
          </>
        ),
      },
      {
        feature: <strong>Data privacy agreements</strong>,
        basic: <RedStopSvg />,
        schoolPlan: (
          <>
            <GreenCheckSvg />
          </>
        ),
      },
    ],
  },
  {
    heading: 'Bonus',
    rows: [
      {
        feature: <strong>No haggling with sales people :&#41;</strong>,
        basic: 'Not applicable',
        schoolPlan: <GreenCheckSvg />,
      },
    ],
  },
];
